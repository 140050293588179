import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import momentPropTypes from 'react-moment-proptypes';

import './Day.scss';

const Day = ({
  date,
  isInMonth = false,
  isSelected = false,
  isAnnotated = false,
  isDisabled = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const className = classnames('Calendar/Day', {
    'Calendar/Day--hidden': !isInMonth,
    'Calendar/Day--selected': isSelected,
    'Calendar/Day--disabled': isDisabled,
  });

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="Calendar/Day__inner">{date.date()}</div>
      {isAnnotated && <div className="Calendar/Day__annotation">*</div>}
    </button>
  );
};

Day.propTypes = {
  date: momentPropTypes.momentObj.isRequired,
  isInMonth: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAnnotated: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default Day;
