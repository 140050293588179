import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Tippy from '@tippyjs/react/headless';

import Month from 'components/Calendar/Month';
import applicationPeriods from 'data/application-periods';

import { isStartDateValid } from './utils';

import './StartDateFilter.scss';

const isMonthValid = month => {
  return applicationPeriods.some(applicationPeriod =>
    month.isSame(applicationPeriod.earliestStartDate, 'month'),
  );
};

const findApplicationPeriodForMonth = month => {
  return applicationPeriods.find(applicationPeriod =>
    month.isSame(applicationPeriod.earliestStartDate, 'month'),
  );
};

const StartDateFilter = ({ startDate, onChange }) => {
  const [month, setMonth] = useState(startDate.clone().startOf('month'));
  const [isOpen, setIsOpen] = useState(false);
  const applicationPeriod = findApplicationPeriodForMonth(month);
  const availableDateRange = [
    applicationPeriod.earliestStartDate,
    applicationPeriod.latestStartDate,
  ];
  const previousStartDate = startDate.clone().subtract(1, 'days');
  const nextStartDate = startDate.clone().add(1, 'days');
  const isPreviousStartDateDisabled = !isStartDateValid(previousStartDate);
  const isNextStartDateDisabled = !isStartDateValid(nextStartDate);

  const previousMonth = month.clone().subtract(1, 'months');
  const nextMonth = month.clone().add(1, 'months');
  const isPreviousMonthDisabled = !isMonthValid(previousMonth);
  const isNextMonthDisabled = !isMonthValid(nextMonth);

  // Reset current month when opening the dropdown
  useEffect(() => {
    setMonth(startDate.clone().startOf('month'));
  }, [startDate, isOpen]);

  const renderDropdown = attrs => (
    <div className="Trips/StartDateFilter__dropdown" tabIndex="-1" {...attrs}>
      <div className="Trips/StartDateFilter__month-header">
        <button
          type="button"
          disabled={isPreviousMonthDisabled}
          className="Trips/StartDateFilter__previous-month-button"
          onClick={() => setMonth(previousMonth)}
        ></button>
        {month.format('MMMM YYYY')}
        <button
          type="button"
          disabled={isNextMonthDisabled}
          className="Trips/StartDateFilter__next-month-button"
          onClick={() => setMonth(nextMonth)}
        ></button>
      </div>
      <Month
        month={month}
        selectedDates={[startDate]}
        availableDateRange={availableDateRange}
        onClickDate={startDate => {
          setIsOpen(false);
          onChange(startDate);
        }}
      />
    </div>
  );

  return (
    <div
      className={classnames('Trips/StartDateFilter', {
        'Trips/StartDateFilter--open': isOpen,
      })}
    >
      {/* Tippy must be wrapped in a div to make it accessible: https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity */}
      <div className="Trips/StartDateFilter__start-date-button-wrapper">
        <Tippy
          visible={isOpen}
          interactive
          placement="bottom-start"
          onClickOutside={() => setIsOpen(false)}
          render={renderDropdown}
        >
          <button
            type="button"
            className="Trips/StartDateFilter__start-date-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            {startDate.format('ddd, MMM D YYYY')}
          </button>
        </Tippy>
      </div>
      <button
        type="button"
        disabled={isPreviousStartDateDisabled}
        className="Trips/StartDateFilter__previous-date-button"
        onClick={() => onChange(previousStartDate)}
      ></button>
      <button
        type="button"
        disabled={isNextStartDateDisabled}
        className="Trips/StartDateFilter__next-date-button"
        onClick={() => onChange(nextStartDate)}
      ></button>
    </div>
  );
};

export default StartDateFilter;
