import React from 'react';

import MeasureDimensions from 'components/MeasureDimensions';

import {
  BLEED,
  FULL_WIDTH,
  FULL_HEIGHT,
  WIDTH,
  HEIGHT,
  STATIC_IMAGE_SRC,
  coordinatesToPolyline,
} from './utils';
import Attribution from './Attribution';

import './index.scss';

const fogPoints = coordinatesToPolyline([
  [-112.05127716064453, 36.237904430823605],
  [-112.00595855712889, 36.19607929145354],
  [-112.00458526611328, 36.17557404062257],
  [-112.04578399658203, 36.12484206220346],
  [-112.06295013427734, 36.10126686921446],
  [-112.06741333007812, 36.07518723980868],
  [-112.06363677978516, 36.04826621193019],
  [-112.08578109741211, 36.03549631945669],
  [-112.13024139404295, 36.03563513376857],
  [-112.16045379638672, 36.04854379528748],
  [-112.22808837890625, 35.99356320483023],
  [-111.93626403808592, 35.991340960635405],
  [-111.93214416503906, 36.284688825024865],
  [-112.22190856933594, 36.284688825024865],
  [-112.22817420959473, 35.99363264895255],
  [-112.16019630432127, 36.04875198216312],
  [-112.16011047363281, 36.060201412392914],
  [-112.1590805053711, 36.066307094101006],
  [-112.14637756347655, 36.092112240259816],
  [-112.11685180664062, 36.126228617859475],
  [-112.06535339355469, 36.18582733706129],
  [-112.08972930908203, 36.21491722092656],
  [-112.08251953125, 36.232642894748324],
  [-112.05127716064453, 36.237904430823605],
]);

const fitBounds = (
  containerWidth,
  containerHeight,
  childWidth,
  childHeight,
) => {
  const aspectRatio = childWidth / childHeight;
  const containerAspectRatio = containerWidth / containerHeight;

  if (aspectRatio <= containerAspectRatio) {
    const height =
      containerHeight >= childHeight ? childHeight : containerHeight;
    return { width: height * aspectRatio, height };
  } else {
    const width = containerWidth >= childWidth ? childWidth : containerWidth;
    return { width, height: width / aspectRatio };
  }
};

const StaticMap = ({ children, noAttribution = false }) => {
  return (
    <MeasureDimensions>
      {({ width: containerWidth, height: containerHeight }) => {
        const { width, height } = fitBounds(
          containerWidth,
          containerHeight,
          WIDTH,
          HEIGHT,
        );
        const bleed = (BLEED * height) / HEIGHT;

        return (
          <div
            className="StaticMap"
            style={{
              width,
              height,
              transform: `translate(${(containerWidth - width) /
                2}px, ${(containerHeight - height) / 2}px)`,
            }}
          >
            <div
              className="StaticMap__bleed"
              style={{
                width: width + 2 * bleed,
                height: height + 2 * bleed,
              }}
            >
              <img
                className="StaticMap__basemap"
                src={STATIC_IMAGE_SRC}
                alt="map"
              />
              <svg
                className="StaticMap__elements"
                viewBox={`0 0 ${FULL_WIDTH} ${FULL_HEIGHT}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <filter id="blurFilter">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="30" />
                  </filter>
                  <marker
                    id="arrowMarker"
                    className="StaticMap__arrow-marker"
                    viewBox="0 0 10 10"
                    refX="8"
                    refY="5"
                    markerWidth="6"
                    markerHeight="6"
                    orient="auto-start-reverse"
                  >
                    <path d="M 0 0 L 10 5 L 0 10" />
                  </marker>
                </defs>
                <polygon
                  className="StaticMap__fog"
                  points={fogPoints}
                  filter="url(#blurFilter)"
                />
                {children}
              </svg>
            </div>
            {!noAttribution && (
              <div className="StaticMap__attribution">
                <Attribution />
              </div>
            )}
          </div>
        );
      }}
    </MeasureDimensions>
  );
};

export default StaticMap;
