import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import './MeasureDimensions.scss';

const MeasureDimensions = ({ children }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const ref = useRef();

  useLayoutEffect(() => {
    const updateDimensions = () => {
      const { width, height } = ref.current.getBoundingClientRect();
      setDimensions({ width, height });
    };
    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className="MeasureDimensions" ref={ref}>
      {children(dimensions)}
    </div>
  );
};

MeasureDimensions.propTypes = {
  children: PropTypes.func.isRequired,
};

export default MeasureDimensions;
