import React from 'react';

import mapFeatures from 'data/map.json';

import { coordinatesToPolyline } from './utils';

import './Segment.scss';

const Segment = ({ segment }) => {
  const feature = mapFeatures.features.find(
    feature => feature.properties.id === segment.code,
  );

  const points = coordinatesToPolyline(feature.geometry.coordinates);

  return (
    <g className="StaticMap/Segment">
      <polyline className="StaticMap/Segment__back" points={points} />
      <polyline className="StaticMap/Segment__front" points={points} />
    </g>
  );
};

export default Segment;
