import React from 'react';

import Filter from './Filter';
import { Item, ItemPro, ItemCon } from './Menu';

const RouteFilter = ({ isBrightAngel, onChange }) => {
  let triggerLabel = 'Routes';
  if (isBrightAngel === true) {
    triggerLabel = 'Bright Angel Trail';
  } else if (isBrightAngel === false) {
    triggerLabel = 'Kaibab Trail';
  }

  return (
    <Filter triggerLabel={triggerLabel} isActive={isBrightAngel !== null}>
      <Item
        label="Bright Angel Trail"
        isSelected={isBrightAngel === true}
        onClick={() => onChange(true)}
      >
        <ItemPro>
          <strong>Slightly easier</strong>. Two miles longer but 400 ft less
          elevation change. Plus an extra water stop at Indian Garden.
        </ItemPro>
      </Item>
      <Item
        label="Kaibab Trail"
        isSelected={isBrightAngel === false}
        onClick={() => onChange(false)}
      >
        <ItemPro>
          <strong>More secluded.</strong> Avoids most day hikers.
        </ItemPro>
        <ItemCon>
          <strong>Harder to get to.</strong> You must take two extra free
          shuttles to get there.
        </ItemCon>
      </Item>
      <Item
        label="No Preference"
        isSelected={isBrightAngel === null}
        onClick={() => onChange(null)}
      />
    </Filter>
  );
};

export default RouteFilter;
