import React from 'react';

import SEGMENT_TYPES from 'data/segment-types';

import Item from './Item';
import Group from './Group';

const getRimName = isSouthRim => (isSouthRim ? 'South Rim' : 'North Rim');
const createItem = (name, type, items = []) => ({ name, type, items });

const getEntryItems = trip => {
  const { entryItinerary, trailItinerary } = trip;
  const trailheadName = trailItinerary.start.name;

  if (!entryItinerary.hasShuttleSegment) {
    return [createItem(`Walk to ${trailheadName}`, SEGMENT_TYPES.WALKING)];
  }

  const items = [];
  entryItinerary.segments.forEach(segment => {
    if (segment.type === SEGMENT_TYPES.SHUTTLE) {
      if (segment.isPaid) {
        const rimName = getRimName(segment.end.isSouthRim);
        items.push(
          createItem(
            `Take Rim to Rim shuttle to ${rimName}`,
            SEGMENT_TYPES.SHUTTLE,
          ),
        );
      } else {
        const name = `Take free shuttle to ${trailheadName}`;
        const lastItem = items.length ? items[items.length - 1] : null;

        // If the last item is also a shuttle, pluralize it instead of adding a new item
        if (lastItem && lastItem.name.startsWith('Take free shuttle')) {
          lastItem.name = `Take free shuttles to ${trailheadName}`;
        } else {
          items.push(createItem(name, SEGMENT_TYPES.SHUTTLE));
        }
      }
    }
  });

  return items;
};

const getExitItems = trip => {
  const { exitItinerary } = trip;

  if (!exitItinerary.hasShuttleSegment) {
    return [createItem(`Walk to your car`, SEGMENT_TYPES.WALKING)];
  }

  let items = [];
  exitItinerary.segments.forEach(segment => {
    if (segment.type === SEGMENT_TYPES.SHUTTLE) {
      if (segment.isPaid) {
        const rimName = getRimName(segment.end.isSouthRim);
        items.push(
          createItem(
            `Take Rim to Rim shuttle to ${rimName}`,
            SEGMENT_TYPES.SHUTTLE,
          ),
        );
      } else {
        const name = `Take free shuttle to ${segment.end.name}`;
        const lastItem = items.length ? items[items.length - 1] : null;

        // If the last item is also a shuttle, pluralize it instead of adding a new item
        if (lastItem && lastItem.name.startsWith('Take free shuttle')) {
          lastItem.name = `Take free shuttles to ${segment.end.name}`;
        } else {
          items.push(createItem(name, SEGMENT_TYPES.SHUTTLE));
        }
      }
    }
  });

  return items;
};

const getItems = trip => {
  const { entryItinerary, trailItinerary, routeName } = trip;
  const parkingName = getRimName(entryItinerary.start.isSouthRim);

  const trailItem = createItem(
    `Hike ${routeName} in ${trailItinerary.days.length} days`,
    SEGMENT_TYPES.HIKING,
    trailItinerary.days.slice(1).map(day => day.start.name),
  );

  return [
    createItem(`Park at ${parkingName}`, null),
    ...getEntryItems(trip),
    trailItem,
    ...getExitItems(trip),
  ];
};

const Summary = ({ trip }) => {
  const items = getItems(trip);

  return (
    <Group>
      {items.map((item, index) => (
        <Item item={item} key={index} />
      ))}
    </Group>
  );
};

export default Summary;
