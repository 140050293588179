import React from 'react';

import SEGMENT_TYPES from 'data/segment-types';

import Segment from './Segment';
import Stop from './Stop';
import Arrow from './Arrow';
import ShuttleSegment from './ShuttleSegment';

const Trip = ({ trip }) => {
  const { days, trailItinerary, entryItinerary } = trip;
  const allSegments = days.reduce((allSegments, day) => {
    day.segments.forEach(segment => allSegments.push(...segment.subSegments));
    return allSegments;
  }, []);
  const hikingSegments = allSegments.filter(
    ({ type }) => type === SEGMENT_TYPES.HIKING,
  );
  const paidShuttleSegment = allSegments.find(
    ({ type, isPaid }) => type === SEGMENT_TYPES.SHUTTLE && isPaid,
  );
  const freeShuttleSegments = allSegments.filter(
    ({ type, isPaid }) => type === SEGMENT_TYPES.SHUTTLE && !isPaid,
  );

  let freeShuttleSegment;
  if (freeShuttleSegments.length === 2) {
    freeShuttleSegment = {
      start: freeShuttleSegments[0].start,
      end: freeShuttleSegments[1].end,
    };
  }

  const accommodationStops = trailItinerary.days
    .map(day => day.end)
    .filter(stop => stop.isAccommodation);
  const parkingStop = entryItinerary.start;

  // TODO add relevant trailheads and shuttle stops
  return (
    <>
      {hikingSegments.map(segment => (
        <Segment segment={segment} key={segment.code} />
      ))}
      {accommodationStops.map(stop => (
        <Stop stop={stop} key={stop.code} />
      ))}
      <Stop stop={parkingStop} />
      <Arrow isSouthbound={trailItinerary.isSouthbound} />
      {paidShuttleSegment && (
        <ShuttleSegment
          start={paidShuttleSegment.start}
          end={paidShuttleSegment.end}
          isPaid
        />
      )}
      {freeShuttleSegment && (
        <ShuttleSegment
          start={freeShuttleSegment.start}
          end={freeShuttleSegment.end}
        />
      )}
    </>
  );
};

export default Trip;
