import React from 'react';

import Filter from './Filter';
import { Item, ItemPro, ItemCon } from './Menu';

const DirectionFilter = ({ isSouthbound, onChange }) => {
  let triggerLabel = 'Direction';
  if (isSouthbound === true) {
    triggerLabel = 'Southbound';
  } else if (isSouthbound === false) {
    triggerLabel = 'Northbound';
  }

  return (
    <Filter triggerLabel={triggerLabel} isActive={isSouthbound !== null}>
      <Item
        label="Southbound"
        isSelected={isSouthbound === true}
        onClick={() => onChange(true)}
      >
        <ItemPro>
          <strong>Less elevation gain.</strong> South Rim is lower than North
          Rim by 1,400 ft.
        </ItemPro>
        <ItemPro>
          <strong>Best when parked at South Rim.</strong> Take the shuttle to
          North Rim before the hike, and hike to South Rim.
        </ItemPro>
      </Item>
      <Item
        label="Northbound"
        isSelected={isSouthbound === false}
        onClick={() => onChange(false)}
      >
        <ItemCon>
          <strong>More elevation gain.</strong> North Rim is higher than South
          Rim by 1,400 ft.
        </ItemCon>
        <ItemPro>
          <strong>Best when parked at North Rim.</strong> Take the shuttle to
          South Rim before the hike, and hike to North Rim.
        </ItemPro>
      </Item>
      <Item
        label="No Preference"
        isSelected={isSouthbound === null}
        onClick={() => onChange(null)}
      />
    </Filter>
  );
};

export default DirectionFilter;
