const successRateByMonthIndex = [
  0.95,
  0.9,
  0.75,
  0.7,
  0.5,
  0.6,
  0.95,
  0.95,
  0.6,
  0.5,
  0.8,
  0.95,
];
export default successRateByMonthIndex;
