import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Wide } from 'components/Layout';

import './Footer.scss';

const Footer = () => {
  const year = moment().format('YYYY');
  return (
    <footer className="Footer">
      <div>© {year} Rim to Rim Permits</div>
      <Wide />
      <Link className="Footer__link" to="/about">
        About
      </Link>
    </footer>
  );
};

export default Footer;
