import React from 'react';
import classnames from 'classnames';

import './Item.scss';

const Item = ({ item }) => {
  const { name, description, type, items = [] } = item;

  return (
    <div className={classnames('Timeline/Item', `Timeline/Item--${type}`)}>
      <div className="Timeline/Item__name">{name}</div>
      {description && (
        <div className="Timeline/Item__description">{description}</div>
      )}
      {items.map(item => (
        <div className="Timeline/Item__item" key={item}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default Item;
