import React from 'react';

import TripListItem from 'components/TripListItem';

import HelpTooltip from './HelpTooltip';

import './TripList.scss';

const TripList = ({ trips, title, selectedTripId, help, onClickTrip }) => {
  if (!trips.length) {
    return null;
  }

  return (
    <>
      {trips.length > 0 && (
        <h2 className="Trips/TripList__header">
          {title}
          {help && <HelpTooltip content={help} />}
        </h2>
      )}

      <div>
        {trips.map(trip => (
          <TripListItem
            trip={trip}
            isActive={trip.id === selectedTripId}
            key={trip.id}
            onClick={() => onClickTrip(trip)}
          />
        ))}
      </div>
    </>
  );
};

export default TripList;
