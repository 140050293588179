import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Index from 'components/Index';
import Trips from 'components/Trips';
import Trip from 'components/Trip';
import About from 'components/About';
import { trackEvent, eventTypes } from 'utils/tracking';
import { useTrackPageView } from 'utils/google-analytics';
import { useResetScrollOnPageChange } from 'utils/routing';

const Routes = () => {
  // These hooks must be placed under <Router/>
  useTrackPageView();
  useResetScrollOnPageChange();

  return (
    <Switch>
      <Route exact path="/" component={Index} />
      <Route exact path="/trips" component={Trips} />
      <Route exact path="/trips/:tripId" component={Trip} />
      {/* <Route exact path="/checkout" component={Checkout} /> */}
      <Route exact path="/about" component={About} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

const App = () => {
  useEffect(() => {
    trackEvent(eventTypes.application.start);
  }, []);

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
