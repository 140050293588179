import STOPS from './stops';
import SEGMENTS from './segments';
import SEGMENT_TYPES from './segment-types';

// Northbound
const BAT_NKT_ITINERARIES = [
  // [[SEGMENTS.A1N, SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N]],
  [[SEGMENTS.A1N], [SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N]],
  [
    [SEGMENTS.A1N, SEGMENTS.A2N],
    [SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N],
  ],
  [[SEGMENTS.A1N, SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N], [SEGMENTS.K4N]],
  [[SEGMENTS.A1N, SEGMENTS.A2N], [SEGMENTS.K2N, SEGMENTS.K3N], [SEGMENTS.K4N]],
  [[SEGMENTS.A1N], [SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N], [SEGMENTS.K4N]],
  [[SEGMENTS.A1N], [SEGMENTS.A2N], [SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N]],
  [
    [SEGMENTS.A1N],
    [SEGMENTS.A2N],
    [SEGMENTS.K2N, SEGMENTS.K3N],
    [SEGMENTS.K4N],
  ],
];

const SKT_NKT_ITINERARIES = [
  // [[SEGMENTS.K1N, SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N]],
  [[SEGMENTS.K1N], [SEGMENTS.K2N, SEGMENTS.K3N, SEGMENTS.K4N]],
  [[SEGMENTS.K1N, SEGMENTS.K2N, SEGMENTS.K3N], [SEGMENTS.K4N]],
  [[SEGMENTS.K1N], [SEGMENTS.K2N, SEGMENTS.K3N], [SEGMENTS.K4N]],
];

// Southbound
const NKT_BAT_ITINERARIES = [
  // [[SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.A2S, SEGMENTS.A1S]],
  [[SEGMENTS.K4S], [SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.A2S, SEGMENTS.A1S]],
  [
    [SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S],
    [SEGMENTS.A2S, SEGMENTS.A1S],
  ],
  [[SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.A2S], [SEGMENTS.A1S]],
  [[SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S], [SEGMENTS.A2S], [SEGMENTS.A1S]],
  [[SEGMENTS.K4S], [SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.A2S], [SEGMENTS.A1S]],
  [[SEGMENTS.K4S], [SEGMENTS.K3S, SEGMENTS.K2S], [SEGMENTS.A2S, SEGMENTS.A1S]],
  [
    [SEGMENTS.K4S],
    [SEGMENTS.K3S, SEGMENTS.K2S],
    [SEGMENTS.A2S],
    [SEGMENTS.A1S],
  ],
];

const NKT_SKT_ITINERARIES = [
  // [[SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.K1S]],
  [[SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S], [SEGMENTS.K1S]],
  [[SEGMENTS.K4S], [SEGMENTS.K3S, SEGMENTS.K2S, SEGMENTS.K1S]],
  [[SEGMENTS.K4S], [SEGMENTS.K3S, SEGMENTS.K2S], [SEGMENTS.K1S]],
];

// Roundtrip from South
// TODO add all other possible itineraries
// const SKT_RT_ITINERARIES = [
//   [
//     [SEGMENTS.A1N, SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N],
//     [SEGMENTS.K4N, SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S],
//     [SEGMENTS.A2S, SEGMENTS.A1S],
//   ],
//   [
//     [SEGMENTS.A1N, SEGMENTS.A2N, SEGMENTS.K2N, SEGMENTS.K3N],
//     [SEGMENTS.K4N, SEGMENTS.K4S, SEGMENTS.K3S, SEGMENTS.K2S],
//     [SEGMENTS.K1S],
//   ],
// ];

// Stop in Phantom Ranch
// const PR_ITINERARIES = [
//   [
//     [SEGMENTS.A1N, SEGMENTS.A2N, SEGMENTS.K2N],
//     [SEGMENTS.K3N, SEGMENTS.K4N],
//   ],
//   [
//     [SEGMENTS.K1N, SEGMENTS.K2N],
//     [SEGMENTS.K3N, SEGMENTS.K4N],
//   ],
//   [
//     [SEGMENTS.K4S, SEGMENTS.K3S],
//     [SEGMENTS.K2S, SEGMENTS.K1S],
//   ],
//   [
//     [SEGMENTS.K4S, SEGMENTS.K3S],
//     [SEGMENTS.K2S, SEGMENTS.A2S, SEGMENTS.A1S],
//   ],
// ];

// Entries/Exits

const NKP_ENTRIES = [
  [SEGMENTS.S1S, SEGMENTS.W3N], // to BAT
  [SEGMENTS.S1S, SEGMENTS.S3N, SEGMENTS.S2S], // to SKT
  [SEGMENTS.W5S], // to NKT
];

const LD_ENTRIES = [
  [SEGMENTS.W4N], // to BAT
  [SEGMENTS.W1S, SEGMENTS.S4N, SEGMENTS.S2S], // to SKT
  [SEGMENTS.W2N, SEGMENTS.S1N, SEGMENTS.W5S], // to NKT
];

const NKP_EXITS = [
  [SEGMENTS.W3S, SEGMENTS.S1N], // from BAT
  [SEGMENTS.S2N, SEGMENTS.S3S, SEGMENTS.S1N], // from SKT
  [SEGMENTS.W5N], // from NKT
];

const LD_EXITS = [
  [SEGMENTS.W4S], // from BAT
  [SEGMENTS.S2N, SEGMENTS.S4S, SEGMENTS.W1N], // from SKT
  [SEGMENTS.W5N, SEGMENTS.S1S, SEGMENTS.W2S], // from NKT
];

const getSegmentsBounds = segments => {
  const { start } = segments[0];
  const { end } = segments[segments.length - 1];
  return { start, end };
};

const toTrailItinerary = days => {
  const lastDay = days[days.length - 1];
  const start = days[0][0].start;
  const end = lastDay[lastDay.length - 1].end;

  const itinerary = {
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 0,
    maxDailyTotalAscent: 0,
    maxDailyTotalDescent: 0,
    maxDailyDistance: 0,
    minDailyDistance: Infinity,
    start,
    end,
    isRoundTrip:
      start === end ||
      (start === STOPS.BAT && end === STOPS.SKT) ||
      (start === STOPS.SKT && end === STOPS.BAT),
    isSouthbound: start === STOPS.NKT,
    isBrightAngel: start === STOPS.BAT || end === STOPS.BAT,
  };

  itinerary.days = days.map(day => {
    const { totalAscent, totalDescent, distance, duration } = getDayStats(day);
    const id = day.map(({ code }) => code).join(',');

    if (totalAscent > itinerary.maxDailyTotalAscent) {
      itinerary.maxDailyTotalAscent = totalAscent;
    }
    if (totalAscent > itinerary.maxDailyTotalDescent) {
      itinerary.maxDailyTotalDescent = totalDescent;
    }
    if (distance > itinerary.maxDailyDistance) {
      itinerary.maxDailyDistance = distance;
    }

    if (distance < itinerary.minDailyDistance) {
      itinerary.minDailyDistance = distance;
    }

    itinerary.totalAscent += totalAscent;
    itinerary.totalDescent += totalDescent;
    itinerary.distance += distance;
    itinerary.duration += duration;

    return {
      id,
      start: day[0].start,
      end: day[day.length - 1].end,
      totalAscent,
      totalDescent,
      distance,
      duration,
      segments: day,
    };
  });

  itinerary.avgDailyTotalAscent = itinerary.totalAscent / days.length;
  itinerary.avgDailyDistance = itinerary.distance / days.length;
  itinerary.id = itinerary.days.map(({ id }) => id).join('|');

  return itinerary;
};

const toItinerary = segments => {
  const { start, end } = getSegmentsBounds(segments);
  const id = segments.map(({ code }) => code).join(',');

  return {
    id,
    start,
    end,
    segments,
    hasShuttleSegment: segments.some(
      segment => segment.type === SEGMENT_TYPES.SHUTTLE,
    ),
    hasPaidShuttleSegment: segments.some(
      segment => segment.type === SEGMENT_TYPES.SHUTTLE && segment.isPaid,
    ),
  };
};

const getDayStats = day => {
  return day.reduce(
    (collector, segment) => {
      collector.totalAscent += segment.totalAscent;
      collector.totalDescent += segment.totalDescent;
      collector.distance += segment.distance;
      collector.duration += segment.duration;
      return collector;
    },
    { totalAscent: 0, totalDescent: 0, distance: 0, duration: 0 },
  );
};

export const ENTRY_ITINERARIES = [...NKP_ENTRIES, ...LD_ENTRIES].map(
  toItinerary,
);
export const EXIT_ITINERARIES = [...NKP_EXITS, ...LD_EXITS].map(toItinerary);
export const TRAIL_ITINERARIES = [
  ...BAT_NKT_ITINERARIES,
  ...SKT_NKT_ITINERARIES,
  ...NKT_BAT_ITINERARIES,
  ...NKT_SKT_ITINERARIES,
].map(days => toTrailItinerary(days));

// TRAIL_ITINERARIES.push(
//   ...SKT_RT_ITINERARIES.map(days => toTrailItinerary(days)),
// );
//
// TRAIL_ITINERARIES.push(
//   ...PR_ITINERARIES.map(days => toTrailItinerary(days)),
// );
