import React from 'react';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';

import {
  buildQueryParams,
  parseQueryParams,
  isTripIdValid,
  isStartDateValid,
} from 'components/Trips/utils';
import { Tall } from 'components/Layout';
import TRIPS from 'data/trips';
import Footer from 'components/Footer';

import Tasks from './Tasks';
import Summary from './Summary';
import Permit from './Permit';

import './index.scss';

const getBackUrl = (trip, startDate) => {
  const query = buildQueryParams({ selectedTripId: trip.id, startDate });
  return `/trips?${query}`;
};

const Trip = () => {
  const location = useLocation();
  const params = useParams();

  const tripId = decodeURIComponent(params.tripId);
  const { startDate } = parseQueryParams(location);
  const trip = TRIPS.find(trip => trip.id === tripId);

  if (!isTripIdValid(tripId) || !isStartDateValid(startDate)) {
    return <Redirect to="/" />;
  }

  const endDate = startDate.clone().add(trip.days.length - 1, 'day');

  return (
    <div className="Trip">
      <Link className="Trip__back-link" to={getBackUrl(trip, startDate)}>
        Back to results
      </Link>
      <div className="Trip__content">
        <Tall size={100} />
        <h1 className="Trip__title">It’s really happening!</h1>
        <Tall size={20} />
        <div className="Trip__date-range">
          {startDate.format('ddd, MMM D')} &rarr;{' '}
          {endDate.format('ddd, MMM D YYYY')}
        </div>
        <Tall size={80} />
        <Tasks trip={trip} startDate={startDate} />
        <Tall size={100} />
        <Permit trip={trip} startDate={startDate} />
        <Tall size={80} />
        <hr className="Trip__divider" />
        <Tall size={80} />
        <Summary trip={trip} startDate={startDate} />
        <Tall size={140} />
        <Footer />
      </div>
    </div>
  );
};

export default Trip;
