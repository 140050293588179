import React from 'react';

import Detailed from './Detailed';
import Summary from './Summary';

const Timeline = ({ trip, startDate, isExpanded }) => {
  const Component = isExpanded ? Detailed : Summary;

  return (
    <div className="Timeline">
      <Component trip={trip} startDate={startDate} />
    </div>
  );
};

export default Timeline;
