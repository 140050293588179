import React from 'react';
import Tippy from '@tippyjs/react/headless';

import './Tooltip.scss';

const Tooltip = ({ content, children }) => {
  const renderContent = attrs => (
    <div className="Index/Tooltip__tooltip" tabIndex="-1" {...attrs}>
      {content}
    </div>
  );

  return (
    <Tippy render={renderContent} delay={[200, null]}>
      <span className="Index/Tooltip">{children}</span>
    </Tippy>
  );
};

export default Tooltip;
