import React from 'react';

import permitRequestPreview from 'assets/permit-request-preview.png';
import permitRequest from 'assets/permit-request.pdf';
import { Tall } from 'components/Layout';
import { findApplicationPeriodForStartDate } from 'data/application-periods';
import { trackEvent, eventTypes } from 'utils/tracking';

import './Permit.scss';

const Permit = ({ trip, startDate }) => {
  const applicationPeriod = findApplicationPeriodForStartDate(startDate);

  const trackDownload = () => {
    trackEvent(eventTypes.trip.permit.download, { tripId: trip.id });
  };

  return (
    <div className="Trip/Permit" id="permit-application">
      <h2 className="Trip/Permit__title">
        <span className="Trip/Permit__title-inner">
          Apply for a backcountry permit
        </span>
      </h2>
      <Tall size={80} />
      <div className="Trip/Permit__inner">
        <div className="Trip/Permit__column">
          <h3 className="Trip/Permit__option-title">Fax it yourself</h3>
          <Tall size={20} />

          <a
            href={permitRequest}
            target="_blank"
            rel="noopener noreferrer"
            className="Trip/Permit__card"
            onClick={trackDownload}
          >
            <p>
              <strong>Mail or fax this form.</strong> No emails or phone calls
              accepted.
            </p>
            <p>
              <strong>
                Send between{' '}
                {applicationPeriod.applicationStartDate.format('MMM D, YYYY')}{' '}
                and{' '}
                {applicationPeriod.applicationDeadline.format('MMM D, YYYY')}.
              </strong>
            </p>
            <img src={permitRequestPreview} alt="Application form" />
            <button type="button" className="Trip/Permit__download-button">
              Download
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Permit;
