import STOPS, { PARKINGS, TRAILHEADS } from './stops';
import { generateCombinedSegment } from './segments';
import {
  ENTRY_ITINERARIES,
  EXIT_ITINERARIES,
  TRAIL_ITINERARIES,
} from './itineraries';

const getTripScore = trip => {
  const { trailItinerary, exitItinerary } = trip;

  let score = 0;
  const maxDailyDuration = trailItinerary.days.reduce(
    (maxDailyDuration, day) =>
      day.duration > maxDailyDuration ? day.duration : maxDailyDuration,
    0,
  );
  const minDailyDuration = trailItinerary.days.reduce(
    (minDailyDuration, day) =>
      day.duration < minDailyDuration ? day.duration : minDailyDuration,
    Infinity,
  );
  // Penalize day imbalance
  if (maxDailyDuration >= 2 * minDailyDuration) {
    score--;
  }
  // Penalize long days
  if (maxDailyDuration >= 5) {
    score--;
  }
  // Penalize northbound (more total elevation)
  if (!trailItinerary.isSouthbound) {
    score = score - 0.5;
  }
  // Penalize trips ending with shuttle
  if (exitItinerary.hasShuttleSegment) {
    score--;
  }
  return score;
};

const addSubSegments = segment => ({
  ...segment,
  subSegments: [segment],
});

const constructDays = ({ entryItinerary, trailItinerary, exitItinerary }) => {
  const days = [
    {
      segments: entryItinerary.segments.map(addSubSegments),
    },
  ];
  trailItinerary.days.forEach((trailDay, index) => {
    // Add day
    if (index >= days.length) {
      days.push({ segments: [] });
    }
    const day = days[index];
    day.segments.push(generateCombinedSegment(trailDay.segments));
  });

  const lastDay = days[days.length - 1];
  lastDay.segments.push(...exitItinerary.segments.map(addSubSegments));

  return days;
};

const generateTrips = () => {
  const trips = [];
  PARKINGS.forEach(parking => {
    TRAILHEADS.forEach(entryTrailhead => {
      const entryItinerary = ENTRY_ITINERARIES.find(entry => {
        return entry.start === parking && entry.end === entryTrailhead;
      });
      const trailItineraries = TRAIL_ITINERARIES.filter(trailItinerary => {
        return trailItinerary.start === entryTrailhead;
      });
      trailItineraries.forEach(trailItinerary => {
        const exitTrailhead = trailItinerary.end;
        const exitItinerary = EXIT_ITINERARIES.find(exit => {
          return exit.start === exitTrailhead && exit.end === parking;
        });

        const days = constructDays({
          entryItinerary,
          trailItinerary,
          exitItinerary,
        });

        const routeName =
          trailItinerary.start === STOPS.BAT || trailItinerary.end === STOPS.BAT
            ? 'Bright Angel Trail'
            : 'Kaibab Trail';

        const trip = {
          id: `${entryItinerary.id}-${trailItinerary.id}-${exitItinerary.id}`,
          entryItinerary,
          trailItinerary,
          exitItinerary,
          routeName,
          directionName: trailItinerary.isSouthbound
            ? 'Southbound'
            : 'Northbound',
          parkAtSouthRim: days[0].segments[0].start === STOPS.LD,
          days,
        };

        trip.camps = trailItinerary.days.slice(1).map(({ start }) => start);
        trip.score = getTripScore(trip);

        if (trip.score > -Infinity) {
          trips.push(trip);
        }
      });
    });
  });
  return trips;
};

const TRIPS = generateTrips();
console.log(TRIPS);
export default TRIPS;
