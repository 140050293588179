import amplitude from 'amplitude-js';

import { IS_PRODUCTION, AMPLITUDE_API_KEY } from 'constants.js';
import eventTypes from 'utils/eventTypes';

amplitude.getInstance().init(AMPLITUDE_API_KEY);

export const trackEvent = (eventName, properties) => {
  if (!eventName) {
    console.error("Can't track undefined event names");
    return;
  }

  if (!IS_PRODUCTION) {
    console.info('Event Tracked', eventName, properties);
    return;
  }

  amplitude.getInstance().logEvent(eventName, properties);
};

export const trackCustomer = (email, properties) => {
  if (!email) {
    console.error("Can't track customers with undefined email");
    return;
  }

  if (!IS_PRODUCTION) {
    console.info('Customer Tracked', email, properties);
    return;
  }

  amplitude.getInstance().setUserId(email);
  amplitude.getInstance().setUserProperties(properties);
};

export { eventTypes };
