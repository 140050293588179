export const sumBy = (array, key) =>
  array.reduce((sum, item) => sum + item[key], 0);

export const getRange = (start, end) =>
  Array.from({ length: end - start + 1 }, (_, index) => start + index);

export const toggleItem = (array, item, accessor = item => item) => {
  const value = accessor(item);
  const index = array.findIndex(_item => accessor(_item) === value);
  const newArray = array.slice();

  if (index >= 0) {
    newArray.splice(index, 1);
  } else {
    newArray.push(item);
  }

  return newArray;
};

export const addItem = (array, ...items) =>
  array
    .concat(items)
    .filter((candidate, i, next) => next.indexOf(candidate) === i);
