import React from 'react';
import Tippy from '@tippyjs/react/headless';

import { ReactComponent as HelpIcon } from 'assets/help-circle.svg';

import './HelpTooltip.scss';

const HelpTooltip = ({ content }) => {
  const renderContent = attrs => (
    <div className="Trips/HelpTooltip__tooltip" tabIndex="-1" {...attrs}>
      {content}
    </div>
  );

  return (
    <Tippy render={renderContent} delay={[200, null]}>
      <HelpIcon className="Trips/HelpTooltip__icon" />
    </Tippy>
  );
};

export default HelpTooltip;
