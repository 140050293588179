import React from 'react';
import { useHistory } from 'react-router-dom';

import { Tall } from 'components/Layout';
import image from 'assets/jordan.jpg';

import './index.scss';

const JORDAN_EMAIL = 'jordan@rimtorimpermits.com';
const JORDAN_TWITTER_HANDLE = '@jordan_vinc';
const JENNIFER_TWITTER_HANDLE = '@JenniferMHwang';

const About = () => {
  const history = useHistory();
  const onBack = () => history.goBack();

  return (
    <div className="About">
      <button type="button" className="About__back-button" onClick={onBack}>
        Back
      </button>
      <Tall size={100} />
      <div className="About__inner">
        <img
          className="About__image"
          src={image}
          alt="Me in the Grand Canyon"
        />
        <div className="About__text">
          <p>Hi! I'm Jordan Vincent, the creator or Rim to Rim Permits.</p>
          <Tall size={20} />
          <p>
            I love everything outdoors, but I’m terrible at planning. This
            website is the service I wish I had when{' '}
            <a
              className="About__link"
              href={`https://twitter.com/${JENNIFER_TWITTER_HANDLE}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Jennifer Hwang
            </a>{' '}
            and I backpacked in the Grand Canyon in 2019.
          </p>
          <Tall size={20} />
          <p>
            I believe nature should be accessible to everyone. Unfortunately,
            it’s often only accessible to those who can navigate complicated
            rules and regulations.
          </p>
          <Tall size={20} />
          <p>
            There are good reasons for limiting access to preserved spaces. But
            if most people don’t recognize the importance of preserving nature,
            it will be hard to keep those places safe from exploitation. You
            certainly know the transformative experience that spending a night
            in the wilderness can have. Now imagine if every person in the US
            had that experience growing up, would preserving the environment
            still be an issue?{' '}
          </p>
          <Tall size={20} />
          <p>
            So, join me in this effort. It’s time to share our love for the
            outdoors with others!
          </p>
          <Tall size={20} />
          <p>
            You can email me at{' '}
            <a
              className="About__link"
              href={`mailto:${JORDAN_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {JORDAN_EMAIL}
            </a>{' '}
            or you can find me on Twitter at{' '}
            <a
              className="About__link"
              href={`https://twitter.com/${JORDAN_TWITTER_HANDLE}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {JORDAN_TWITTER_HANDLE}
            </a>
            .
          </p>
        </div>
      </div>
      <Tall size={100} />
    </div>
  );
};

export default About;
