import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Tall } from 'components/Layout';
import { buildQueryParams } from 'components/Trips/utils';
import applicationPeriods from 'data/application-periods';
import { trackEvent, eventTypes } from 'utils/tracking';

import ApplicationPeriod from './ApplicationPeriod';

import './StartDate.scss';

const getUrl = startDate => {
  const query = buildQueryParams({ startDate });
  return `/trips?${query}`;
};

const StartDate = () => {
  const [startDate, setStartDate] = useState(null);

  const _setStartDate = startDate => {
    setStartDate(startDate);
    trackEvent(eventTypes.index.startDate.update, {
      startDate: startDate.toISOString(),
    });
  };

  const trackPick = () => {
    trackEvent(eventTypes.index.startDate.pick, {
      startDate: startDate.toISOString(),
    });
  };

  return (
    <section className="Index/StartDate">
      <h2 className="Index/StartDate__title">Pick a Start Date</h2>
      <p className="Index/StartDate__subtitle">
        Here are all start dates available for advance reservation.
      </p>
      {applicationPeriods.map(applicationPeriod => (
        <React.Fragment key={applicationPeriod.earliestStartDate.format()}>
          <Tall size={80} />
          <ApplicationPeriod
            applicationPeriod={applicationPeriod}
            selectedStartDate={startDate}
            onSelectStartDate={_setStartDate}
          />
        </React.Fragment>
      ))}
      {startDate && (
        <div className="Index/StartDate__next-step">
          <div className="Index/StartDate__start-date-label">Start date</div>
          <div className="Index/StartDate__start-date">
            {startDate.format('MMMM D, YYYY')}
          </div>
          <Tall size={16} />
          <Link
            className="Index/StartDate__link"
            to={getUrl(startDate)}
            onClick={trackPick}
          >
            Next: Itinerary
          </Link>
          <Tall size={16} />
          <div className="Index/StartDate__next-step-message">
            You can always change the start date later.
          </div>
        </div>
      )}
    </section>
  );
};

export default StartDate;
