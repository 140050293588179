import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';

import Day from './Day';
import Weekday from './Weekday';

import './Month.scss';

const getMonthDays = date => {
  const mDate = moment.utc(date).startOf('month');
  const daysInMonth = mDate.daysInMonth();
  const days = [];

  // Fill in previous month's days
  const dayOfWeek = mDate.weekday();
  for (let i = dayOfWeek; i > 0; i--) {
    days.push(mDate.clone().subtract(i, 'days'));
  }

  // Fill in this month's days
  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    days.push(mDate.date(dayIndex).clone());
  }

  return days;
};

const getWeekdays = () => {
  const mDate = moment.utc();
  const weekdays = [];
  for (let weekdayIndex = 0; weekdayIndex < 7; weekdayIndex++) {
    weekdays.push(mDate.weekday(weekdayIndex).clone());
  }
  return weekdays;
};

const Month = ({
  month,
  selectedDates = [],
  availableDateRange,
  className,
  annotations = [],
  isDisabled = false,
  displayMonthLabel = false,
  onClickDate,
}) => {
  const days = getMonthDays(month);
  const weekdays = getWeekdays();
  const sortedAnnotations = annotations.sort((a, b) => a.date.diff(b.date));

  return (
    <div
      className={classnames('Calendar/Month', className, {
        'Calendar/Month--disabled': isDisabled,
      })}
    >
      {displayMonthLabel && (
        <div className="Calendar/Month__month">{month.format('MMMM YYYY')}</div>
      )}
      <div className="Calendar/Month__weekdays">
        {weekdays.map(weekday => (
          <Weekday weekday={weekday} key={weekday.toISOString()} />
        ))}
      </div>
      <div className="Calendar/Month__days">
        {days.map(day => {
          const isAnnotated = annotations.some(annotation =>
            annotation.date.isSame(day, 'day'),
          );
          const isSelected = selectedDates.some(selectedDate =>
            day.isSame(selectedDate, 'day'),
          );

          const isInAvailableDateRange =
            !availableDateRange ||
            (availableDateRange[0].isSameOrBefore(day, 'day') &&
              availableDateRange[1].isSameOrAfter(day, 'day'));

          return (
            <Day
              key={day.toISOString()}
              date={day}
              isInMonth={day.isSame(month, 'month')}
              isSelected={isSelected}
              isAnnotated={isAnnotated}
              isDisabled={isDisabled || !isInAvailableDateRange}
              onClick={() => onClickDate(day)}
            />
          );
        })}
      </div>
      {sortedAnnotations.map(annotation => (
        <div
          className="Calendar/Month__annotation"
          key={annotation.date.format()}
        >
          {annotation.text}
        </div>
      ))}
    </div>
  );
};

Month.propTypes = {
  month: momentPropTypes.momentObj.isRequired,
  selectedDates: PropTypes.arrayOf(momentPropTypes.momentObj),
  availableDateRange: PropTypes.arrayOf(momentPropTypes.momentObj),
  annotations: PropTypes.array,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  displayMonthLabel: PropTypes.bool,
  onClickDate: PropTypes.func.isRequired,
};

export default Month;
