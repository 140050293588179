import React from 'react';
import numeral from 'numeral';

import './HikingSegment.scss';

const formatDistance = distance => numeral(distance).format() + ' mi';
const formatElevation = elevation =>
  numeral(Math.round(elevation / 100) * 100).format('0,0') + ' ft';

const HikingSegment = ({ segment, yScale, pixelsPerMile, height }) => {
  const { subSegments, distance, totalAscent, totalDescent } = segment;
  let offsetX = 0;

  const content = [];

  subSegments.forEach(segment => {
    const { start, end, code, distance } = segment;
    const startY = yScale(start.altitude);
    const endY = yScale(end.altitude);
    const polygonWidth = distance * pixelsPerMile;

    const points = [
      [0, startY],
      [polygonWidth, endY],
      [polygonWidth, height],
      [0, height],
    ];

    const linePoints = [
      [0, startY],
      [polygonWidth, endY],
    ];

    content.push(
      <polygon
        className="Chart/HikingSegment__segment"
        transform={`translate(${offsetX}, 0)`}
        points={points.map(point => point.join(',')).join(' ')}
        key={code}
      />,
    );

    content.push(
      <polyline
        className="Chart/HikingSegment__line-back"
        transform={`translate(${offsetX}, 0)`}
        points={linePoints.map(point => point.join(',')).join(' ')}
        key={code + 'l'}
      />,
    );
    content.push(
      <polyline
        className="Chart/HikingSegment__line"
        transform={`translate(${offsetX}, 0)`}
        points={linePoints.map(point => point.join(',')).join(' ')}
        key={code + 'b'}
      />,
    );

    offsetX += polygonWidth;
  });

  content.push(
    <text
      className="Chart/HikingSegment__distance"
      x={offsetX / 2}
      y={height + 18}
      key="distance"
    >
      {formatDistance(distance)}
    </text>,
    <text
      className="Chart/HikingSegment__elevation-change"
      x={offsetX / 2}
      y={height + 43}
      key="totalAscent"
    >
      {!!totalDescent && (
        <tspan className="Chart/HikingSegment__descent">
          -{formatElevation(totalDescent)}
        </tspan>
      )}
      {!!totalAscent && !!totalDescent && ' / '}
      {!!totalAscent && (
        <tspan className="Chart/HikingSegment__ascent">
          +{formatElevation(totalAscent)}
        </tspan>
      )}
    </text>,
  );

  return content;
};

export default HikingSegment;
