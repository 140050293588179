export const milesToKilometers = miles => miles * 1.609;
export const feetToMeters = feet => feet / 3.281;

// Tolber's hiking function
// https://www.wikiwand.com/en/Tobler%27s_hiking_function
export const estimateDuration = (distance, elevationChange) => {
  if (!distance) {
    return 0;
  }
  const dx = milesToKilometers(distance);
  const dh = feetToMeters(elevationChange) / 1000;
  const speed = 6 * Math.exp(-3.5 * Math.abs(dh / dx + 0.05));
  return dx / speed;
};
