import React, { useState } from 'react';
import classnames from 'classnames';
import Tippy from '@tippyjs/react/headless';
import PropTypes from 'prop-types';

import Menu from './Menu';

import './Filter.scss';

const Filter = ({ children, triggerLabel, isActive = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const triggerClassName = classnames('Trips/Filter__trigger', {
    'Trips/Filter__trigger--active': isActive,
    'Trips/Filter__trigger--open': isOpen,
  });

  const trigger = (
    <button
      type="button"
      className={triggerClassName}
      onClick={isOpen ? close : open}
    >
      <div className="Trips/Filter__label">{triggerLabel}</div>
      <div className="Trips/Filter__caret" />
    </button>
  );

  const renderContent = attrs => (
    <Menu tabIndex="-1" onClick={close} {...attrs}>
      {children}
    </Menu>
  );

  return (
    <div className="Trips/Filter">
      <Tippy
        visible={isOpen}
        interactive
        placement="bottom-end"
        onClickOutside={close}
        render={renderContent}
      >
        {trigger}
      </Tippy>
    </div>
  );
};

Filter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  triggerLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

export default Filter;
