import React from 'react';

import './Group.scss';

const Group = ({ title, children }) => {
  return (
    <div className="Timeline/Group">
      {title && <div className="Timeline/Group__title">{title}</div>}
      {children}
    </div>
  );
};

export default Group;
