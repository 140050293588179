const temperatureByMonthIndex = [
  [37.0, 56.7],
  [41.5, 63.7],
  [47.3, 72.4],
  [54.4, 81.9],
  [63.2, 92.1],
  [71.8, 102.6],
  [76.6, 106.0],
  [74.4, 102.6],
  [68.3, 95.6],
  [57.5, 83.0],
  [45.7, 67.7],
  [37.6, 56.7],
];
export default temperatureByMonthIndex;
