import numeral from 'numeral';

// Our service fee
export const ONLINE_SERVICE_FEE = 10;
// Grand Canyon permit application price
export const NPS_APPLICATION_PRICE = 10;
// Grand Canyon backcountry campground price per night per person
export const NPS_PER_PERSON_PER_NIGHT_PRICE = 8;
// Grand Canyon cancellation charge
export const NPS_CANCELLATION_CHARGE = 10;
// Rim to Rim shuttle
export const RIM_TO_RIM_SHUTTLE_PER_PERS_PRICE = 90;

export const getCampgroundNightsPrice = (nightCount, groupSize) => {
  return NPS_PER_PERSON_PER_NIGHT_PRICE * nightCount * groupSize;
};

export const getTotalPrice = (nightCount, groupSize) => {
  return (
    getCampgroundNightsPrice(nightCount, groupSize) +
    NPS_APPLICATION_PRICE +
    ONLINE_SERVICE_FEE
  );
};

export const getDeniedRefund = (nightCount, groupSize) => {
  return getTotalPrice(nightCount, groupSize) - ONLINE_SERVICE_FEE;
};

export const getCancellationRefund = (nightCount, groupSize) => {
  return (
    getTotalPrice(nightCount, groupSize) -
    ONLINE_SERVICE_FEE -
    NPS_CANCELLATION_CHARGE
  );
};

export const getAveragePerPersonTotalPrice = () => {
  return (
    RIM_TO_RIM_SHUTTLE_PER_PERS_PRICE +
    NPS_APPLICATION_PRICE +
    NPS_PER_PERSON_PER_NIGHT_PRICE * 1
  );
};

export const formatPrice = price => numeral(price).format('$0');
