import React from 'react';
import classnames from 'classnames';

import './Menu.scss';

export const Item = ({ label, isSelected, children, onClick }) => (
  <div
    className={classnames('Trips/Menu__item', {
      'Trips/Menu__item--selected': isSelected,
    })}
    onClick={onClick}
  >
    <div className="Trips/Menu__item-label">{label}</div>
    {children}
  </div>
);

export const ItemPro = ({ children }) => (
  <div className="Trips/Menu__item-pro">{children}</div>
);
export const ItemCon = ({ children }) => (
  <div className="Trips/Menu__item-con">{children}</div>
);

const Menu = ({ children, ...rest }) => (
  <div className="Trips/Menu" {...rest}>
    {children}
  </div>
);

export default Menu;
