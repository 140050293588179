import moment from 'moment';

const notesByMonthIndex = [
  'The road to North Rim is closed.',
  'The road to North Rim is closed.',
  'The road to North Rim is closed.',
  'The road to North Rim is closed.',
  'The road to North Rim opens mid-May.',
  '',
  '',
  '',
  '',
  '',
  'The road to North Rim may be closed. The Rim to Rim shuttle service ends on Nov 15.',
  'The road to North Rim is closed.',
];

// TODO: applicationDeadline must be in MST time
// https://www.nps.gov/grca/planyourvisit/backcountry-permit.htm
function generateApplicationPeriods() {
  const MONTH_COUNT = 8;

  return Array.from({ length: MONTH_COUNT }).map((_, index) => {
    const earliestStartDate = moment
      .utc()
      .startOf('month')
      .add(index, 'month');
    const latestStartDate = earliestStartDate.clone().endOf('month');
    const applicationStartDate = earliestStartDate
      .clone()
      .subtract(5, 'months')
      .set('date', 20);
    const applicationDeadline = earliestStartDate.clone().subtract(4, 'months');
    const notes = notesByMonthIndex[earliestStartDate.month()];

    return {
      earliestStartDate,
      latestStartDate,
      applicationStartDate,
      applicationDeadline,
      notes,
    };
  });
}

const applicationPeriods = generateApplicationPeriods();

export const findApplicationPeriodForStartDate = startDate => {
  return applicationPeriods.find(
    applicationPeriod =>
      startDate.isSameOrAfter(applicationPeriod.earliestStartDate) &&
      startDate.isSameOrBefore(applicationPeriod.latestStartDate),
  );
};

export default applicationPeriods;
