import React from 'react';
import classnames from 'classnames';
import numeral from 'numeral';

import { ReactComponent as ParkingIcon } from 'assets/parking-box.svg';
import { ReactComponent as SouthboundIcon } from 'assets/southbound.svg';
import { ReactComponent as NorthboundIcon } from 'assets/northbound.svg';

import './TripListItem.scss';

const TripListItem = ({ trip, isActive, onClick }) => {
  const { trailItinerary, days, parkAtSouthRim } = trip;
  const { isRoundTrip, isSouthbound, isBrightAngel } = trailItinerary;

  const DirectionIcon = isSouthbound ? SouthboundIcon : NorthboundIcon;
  const routeName = isBrightAngel ? 'Bright Angel' : 'Kaibab';
  const parking = parkAtSouthRim ? 'South Rim' : 'North Rim';

  const className = classnames('TripListItem', {
    'TripListItem--active': isActive,
    'TripListItem--clickable': !!onClick,
  });

  return (
    <div className={className} onClick={onClick}>
      {!isRoundTrip && (
        <div className="TripListItem__direction">
          <DirectionIcon
            className={classnames(
              'TripListItem__direction-icon',
              `TripListItem__direction-icon--${
                isSouthbound ? 'southbound' : 'northbound'
              }`,
            )}
          />
        </div>
      )}
      <div className="TripListItem__route">{routeName}</div>
      <div className="TripListItem__parking">
        <ParkingIcon className="TripListItem__parking-icon" />
        {parking}
      </div>
      <div className="TripListItem__distance">
        {trailItinerary.days.map((day, index) => (
          <span className="TripListItem__daily-distance" key={index}>
            {numeral(day.distance).format()}
          </span>
        ))}
        <span> mi</span>
      </div>
      <div className="TripListItem__day-count">{days.length}d</div>
    </div>
  );
};

export default TripListItem;
