import React from 'react';
import numeral from 'numeral';

import monthlySuccessRates from 'data/monthly-success-rates';
import monthlyTemperatures from 'data/monthly-temperatures';
import monthlyPrecipitationDays from 'data/monthly-precipitation-days';
import Month from 'components/Calendar/Month';
import { Row, Tall, Wide } from 'components/Layout';
import Tooltip from 'components/Tooltip';

import './ApplicationPeriod.scss';

const formatTemperature = temperature => `${numeral(temperature).format()}°`;

const ApplicationPeriod = ({
  applicationPeriod,
  selectedStartDate,
  onSelectStartDate,
}) => {
  const {
    earliestStartDate,
    latestStartDate,
    applicationDeadline,
    notes,
  } = applicationPeriod;

  const monthIndex = earliestStartDate.month();
  const successRate = monthlySuccessRates[monthIndex];
  const temperatures = monthlyTemperatures[monthIndex];
  const precipitationDays = monthlyPrecipitationDays[monthIndex];

  return (
    <div className="Index/ApplicationPeriod">
      <h3 className="Index/ApplicationPeriod__title">
        {earliestStartDate.format('MMMM YYYY')}
      </h3>
      <Tall size={20} />
      <Row>
        <Month
          month={earliestStartDate}
          selectedDates={selectedStartDate ? [selectedStartDate] : []}
          availableDateRange={[earliestStartDate, latestStartDate]}
          onClickDate={onSelectStartDate}
        />
        <Wide size={40} />
        <Wide className="Index/ApplicationPeriod__inner">
          <Tall size={4} />
          <table className="Index/ApplicationPeriod__info-table">
            <tbody>
              <tr className="Index/ApplicationPeriod__info">
                <td>
                  <Tooltip content="The average low and high temperatures inside the canyon for this month.">
                    Temperature
                  </Tooltip>
                </td>
                <td>
                  {formatTemperature(temperatures[0])} /{' '}
                  {formatTemperature(temperatures[1])}
                </td>
              </tr>
              <tr className="Index/ApplicationPeriod__info">
                <td>
                  <Tooltip content="The average number of days with 0.1 inch or more of rain/snow. Rain never lasts long, it usually occurs during storms.">
                    Precipitation
                  </Tooltip>
                </td>
                <td>
                  {precipitationDays} {precipitationDays === 1 ? 'day' : 'days'}
                </td>
              </tr>
              <tr className="Index/ApplicationPeriod__info">
                <td>
                  <Tooltip content="The historical lottery success rate for this month. A lottery system is used when the number backpacking permit applications exceeds the quota. ">
                    Success rate
                  </Tooltip>
                </td>
                <td>{numeral(successRate).format('0%')}</td>
              </tr>
              <tr className="Index/ApplicationPeriod__info">
                <td>
                  <Tooltip content="Deadline to submit an application for permits starting in this month.">
                    Apply by
                  </Tooltip>
                </td>
                <td>{applicationDeadline.format('MMM D, YYYY')}</td>
              </tr>
            </tbody>
          </table>
          {!!notes && (
            <>
              <Tall size={32} />
              <div className="Index/ApplicationPeriod__notes">{notes}</div>
            </>
          )}
        </Wide>
      </Row>
    </div>
  );
};

export default ApplicationPeriod;
