import React from 'react';
import classnames from 'classnames';

import './index.scss';

export const Row = ({ children, verticalAlign = 'normal' }) => (
  <div className="Layout/Row" style={{ alignItems: verticalAlign }}>
    {children}
  </div>
);

export const Tall = ({ size, children }) => {
  return (
    <div
      className={classnames('Layout/Tall', { 'Layout/Tall--fluid': !size })}
      style={size ? { height: size } : {}}
    >
      {children}
    </div>
  );
};

export const Wide = ({ size, children }) => {
  return (
    <div
      className={classnames('Layout/Wide', { 'Layout/Wide--fluid': !size })}
      style={size ? { width: size } : {}}
    >
      {children}
    </div>
  );
};
