import geoViewport from '@mapbox/geo-viewport';

import { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE } from 'constants.js';

const desiredWestLng = -112.15324401855467;
const desiredEastLng = -112.02175140380858;
const desiredSouthLat = 36.02883294476566;
const desiredNorthLat = 36.22848880036843;

// Bleed width around the edge (in degrees)
const REAL_BLEED = 0.02;

const desiredRealWidth = desiredEastLng - desiredWestLng;
const desiredRealHeight = desiredNorthLat - desiredSouthLat;
const aspectRatio = desiredRealWidth / desiredRealHeight;

export const HEIGHT = 800;
const pxPerDegree = HEIGHT / desiredRealHeight;
// Match the aspect ratio from the real coordinates
export const WIDTH = Math.round(HEIGHT * aspectRatio);
export const BLEED = Math.round(REAL_BLEED * pxPerDegree);
export const FULL_HEIGHT = HEIGHT + 2 * BLEED;
export const FULL_WIDTH = WIDTH + 2 * BLEED;

const TILE_SIZE = 512;

// Desired bounds
const { center, zoom } = geoViewport.viewport(
  [
    desiredWestLng - REAL_BLEED,
    desiredSouthLat - REAL_BLEED,
    desiredEastLng + REAL_BLEED,
    desiredNorthLat + REAL_BLEED,
  ],
  [FULL_WIDTH, FULL_HEIGHT],
  undefined,
  undefined,
  TILE_SIZE,
  true,
);

// Real bounds
const [westLng, southLat, eastLng, northLat] = geoViewport.bounds(
  center,
  zoom,
  [FULL_WIDTH, FULL_HEIGHT],
  TILE_SIZE,
);

export const STATIC_IMAGE_SRC = `https://api.mapbox.com/styles/v1/${MAPBOX_STYLE}/static/${center[0]},${center[1]},${zoom},0/${FULL_WIDTH}x${FULL_HEIGHT}@2x?access_token=${MAPBOX_ACCESS_TOKEN}&logo=false&attribution=false`;

export const lngToX = lng =>
  ((westLng - lng) / (westLng - eastLng)) * FULL_WIDTH;
export const latToY = lat =>
  ((northLat - lat) / (northLat - southLat)) * FULL_HEIGHT;

export const coordinatesToPolyline = points =>
  points.map(([lng, lat]) => `${lngToX(lng)},${latToY(lat)}`).join(' ');
