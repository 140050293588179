import React from 'react';
import numeral from 'numeral';

import Filter from './Filter';
import { Item } from './Menu';

const formatDistance = distance => `Max. ${numeral(distance).format()} mi/day`;

const MaxDailyDistanceFilter = ({ maxDailyDistance, onChange }) => {
  const triggerLabel = maxDailyDistance
    ? formatDistance(maxDailyDistance)
    : 'Distance';

  return (
    <Filter triggerLabel={triggerLabel} isActive={maxDailyDistance !== null}>
      {[7.2, 10, 14].map(ascent => (
        <Item
          label={formatDistance(ascent)}
          isSelected={ascent === maxDailyDistance}
          key={ascent}
          onClick={() => onChange(ascent)}
        />
      ))}
      <Item
        label="No Preference"
        isSelected={maxDailyDistance === null}
        onClick={() => onChange(null)}
      />
    </Filter>
  );
};

export default MaxDailyDistanceFilter;
