import React from 'react';

import { Tall } from 'components/Layout';
import Tooltip from 'components/Tooltip';

import './Tasks.scss';

const Tasks = ({ trip, startDate }) => {
  const { days, exitItinerary } = trip;
  const shuttleDate = startDate.clone();

  if (exitItinerary.hasPaidShuttleSegment) {
    shuttleDate.add(days.length - 1, 'days');
  }

  return (
    <div className="Trip/Tasks">
      <div className="Trip/Tasks__message">Remaining steps</div>
      <Tall size={16} />
      <div className="Trip/Tasks__tasks">
        <a href="#permit-application" className="Trip/Tasks__task">
          <div className="Trip/Tasks__task-index">1</div>
          <div className="Trip/Tasks__task-primary-add-on Trip/Tasks__task-primary-add-on--highlighted">
            Now
          </div>
          <div className="Trip/Tasks__task-primary-inner">
            Apply for a backcountry permit
          </div>
        </a>
        <Tall size={16} />
        <div className="Trip/Tasks__task">
          <div className="Trip/Tasks__task-index">2</div>
          <div className="Trip/Tasks__task-primary-add-on">
            <Tooltip content="You should wait for your backcountry permit to get approved. Shuttle reservations never fill out!">
              After your permit is approved
            </Tooltip>
          </div>
          <div className="Trip/Tasks__task-primary-inner">
            Book the Rim to Rim shuttle
            <br />
            for {shuttleDate.format('MMMM D, YYYY')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
