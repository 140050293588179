import React, { useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';

import TRIPS from 'data/trips';
import TripCard from 'components/TripCard';
import StaticMap from 'components/StaticMap';
import Trip from 'components/StaticMap/Trip';
import Attribution from 'components/StaticMap/Attribution';
import { trackEvent, eventTypes } from 'utils/tracking';

import DirectionFilter from './DirectionFilter';
import RouteFilter from './RouteFilter';
import ParkingFilter from './ParkingFilter';
import DayCountFilter from './DayCountFilter';
import MaxDailyDistanceFilter from './MaxDailyDistanceFilter';
import StartDateFilter from './StartDateFilter';
import TripList from './TripList';
import {
  parseQueryParams,
  buildQueryParams,
  filterSortTrips,
  prioritizeTrips,
  isStartDateValid,
} from './utils';
import './index.scss';

const findBestTripId = filters => {
  const filteredTrips = filterSortTrips(TRIPS, filters);
  return filteredTrips.length ? filteredTrips[0].id : null;
};

const RESET_FILTERS = {
  isSouthbound: null,
  isBrightAngel: null,
  parkAtSouthRim: null,
};

const Trips = () => {
  const location = useLocation();
  const history = useHistory();
  const filters = parseQueryParams(location);

  const {
    dayCount,
    startDate,
    isSouthbound,
    isBrightAngel,
    parkAtSouthRim,
    maxDailyDistance,
    selectedTripId,
  } = filters;

  const updateFilters = params => {
    const withDefault = (key, defaultValue) => {
      return params.hasOwnProperty(key) ? params[key] : defaultValue;
    };

    const updatedFilters = {
      dayCount: withDefault('dayCount', dayCount),
      startDate: withDefault('startDate', startDate),
      isSouthbound: withDefault('isSouthbound', isSouthbound),
      isBrightAngel: withDefault('isBrightAngel', isBrightAngel),
      parkAtSouthRim: withDefault('parkAtSouthRim', parkAtSouthRim),
      maxDailyDistance: withDefault('maxDailyDistance', maxDailyDistance),
    };

    // Reset selected trip
    updateUrl({
      ...updatedFilters,
      selectedTripId: findBestTripId(updatedFilters),
    });
  };

  const updateFiltersManually = params => {
    // Event tracking
    const eventProperties = { ...params };
    if (params.startDate) {
      eventProperties.startDate = params.startDate.format('YYYY-MM-DD');
    }
    trackEvent(eventTypes.trips.filters.update, eventProperties);

    updateFilters(params);
  };

  const clearFilters = () => {
    const filters = { ...RESET_FILTERS, startDate };
    const bestTripId = findBestTripId(filters);
    trackEvent(eventTypes.trips.filters.clear);
    updateUrl({ ...filters, selectedTripId: bestTripId });
  };

  // Selects the first trip on the very first render is none already selected
  // Checks start date validity
  useEffect(() => {
    if (!isStartDateValid(startDate)) {
      history.push('/');
    } else if (!selectedTripId) {
      const bestTripId = findBestTripId(filters);
      if (bestTripId) {
        updateUrl({
          ...filters,
          selectedTripId: bestTripId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUrl = params => {
    const query = buildQueryParams(params);
    history.replace(`/trips?${query}`);
  };

  const selectTrip = trip => {
    const tripId = trip.id;
    updateUrl({ ...filters, selectedTripId: tripId });
    trackEvent(eventTypes.trips.selectedTrip.update, { tripId });
  };

  const [bestTrips, otherTrips] = prioritizeTrips(TRIPS, filters);

  const selectedTrip = TRIPS.find(({ id }) => id === selectedTripId);

  const pickSelectedTrip = () => {
    const query = buildQueryParams({ startDate });
    history.push(`/trips/${encodeURIComponent(selectedTripId)}?${query}`);
    trackEvent(eventTypes.trips.selectedTrip.pick, { tripId: selectedTripId });
  };

  return (
    <div className="Trips">
      <div className="Trips__results">
        <div className="Trips__top-filters">
          <Link to="/" className="Trips__back-button" />
          <StartDateFilter
            startDate={startDate}
            onChange={startDate => updateFiltersManually({ startDate })}
          />
        </div>
        <div className="Trips__filters-label">Filters</div>
        <div className="Trips__bottom-filters">
          <DirectionFilter
            isSouthbound={isSouthbound}
            onChange={isSouthbound => updateFiltersManually({ isSouthbound })}
          />
          <RouteFilter
            isBrightAngel={isBrightAngel}
            onChange={isBrightAngel => updateFiltersManually({ isBrightAngel })}
          />
          <ParkingFilter
            parkAtSouthRim={parkAtSouthRim}
            onChange={parkAtSouthRim =>
              updateFiltersManually({ parkAtSouthRim })
            }
          />
          <DayCountFilter
            dayCount={dayCount}
            onChange={dayCount => updateFiltersManually({ dayCount })}
          />
          <MaxDailyDistanceFilter
            maxDailyDistance={maxDailyDistance}
            onChange={maxDailyDistance =>
              updateFiltersManually({ maxDailyDistance })
            }
          />
        </div>

        <div className="Trips__itineraries">
          <TripList
            trips={bestTrips}
            title="Best itineraries"
            help="Best itineraries have days of similar difficulty, have less overall elevation gain, and avoid ending with a shuttle ride."
            selectedTripId={selectedTripId}
            onClickTrip={selectTrip}
          />
          <TripList
            trips={otherTrips}
            title="Other itineraries"
            selectedTripId={selectedTripId}
            onClickTrip={selectTrip}
          />
          {!bestTrips.length && (
            <div className="Trips__no-matches-notice">
              There are no matching trips.
              <br />
              Try updating filters, or{' '}
              <button
                type="button"
                className="Trips__clear-button"
                onClick={clearFilters}
              >
                clear them all
              </button>
              .
            </div>
          )}
        </div>
      </div>

      <div className="Trips__selection">
        <div className="Trips__trip-card-container">
          {selectedTrip && (
            <TripCard
              trip={selectedTrip}
              startDate={startDate}
              stretch
              onPick={pickSelectedTrip}
            />
          )}
        </div>
        <div className="Trips__map-container">
          {selectedTrip && (
            <StaticMap noAttribution>
              <Trip trip={selectedTrip} />
            </StaticMap>
          )}
        </div>
        <div className="Trips__map-attribution">
          <Attribution />
        </div>
      </div>
    </div>
  );
};

export default Trips;
