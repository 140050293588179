import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  IS_PRODUCTION,
  GOOGLE_ANALYTICS_ID,
  GOOGLE_ADS_CONVERSION_ID,
} from 'constants.js';
import { ONLINE_SERVICE_FEE } from 'utils/pricing';

const _trackPageView = path => {
  if (window.gtag && IS_PRODUCTION) {
    window.gtag('event', 'page_view', {
      page_path: path,
      send_to: GOOGLE_ANALYTICS_ID,
    });
  }
};

export const trackConversion = () => {
  if (window.gtag && IS_PRODUCTION) {
    window.gtag('event', 'conversion', {
      send_to: GOOGLE_ADS_CONVERSION_ID,
      value: ONLINE_SERVICE_FEE,
      currency: 'USD',
    });
  }
};

export const useTrackPageView = () => {
  const { pathname, search } = useLocation();
  const path = pathname + search;

  useEffect(() => {
    _trackPageView(path);
  }, [path]);
};
