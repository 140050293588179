import queryString from 'query-string';
import moment from 'moment';

import TRIPS from 'data/trips';
import { findApplicationPeriodForStartDate } from 'data/application-periods';

const DATE_FORMAT = 'YYYY-MM-DD';

const parseDate = value => {
  let parsed = moment.utc(value, DATE_FORMAT);
  return parsed.isValid() ? parsed : null;
};

const parseBoolean = (value, trueFlag, flaseFlag) => {
  if (value === trueFlag) {
    return true;
  } else if (value === flaseFlag) {
    return false;
  } else {
    return null;
  }
};

const parseNumber = value => {
  let parsed = Number.parseFloat(value);
  return Number.isNaN(parsed) ? null : parsed;
};

const serializeDate = value => {
  if (value) {
    return value.format(DATE_FORMAT);
  }
};

const serializeBoolean = (value, trueFlag, flaseFlag) => {
  if (value === true) {
    return trueFlag;
  } else if (value === false) {
    return flaseFlag;
  }
};

const serializeNumber = value => {
  if (!!value || value === 0) {
    return value;
  }
};

export const parseQueryParams = location => {
  const {
    days,
    start,
    direction,
    park,
    route,
    distance,
    id,
  } = queryString.parse(location.search);

  const dayCount = parseNumber(days);
  const startDate = parseDate(start);
  const maxDailyDistance = parseNumber(distance);
  const isSouthbound = parseBoolean(direction, 's', 'n');
  const isBrightAngel = parseBoolean(route, 'bat', 'kt');
  const parkAtSouthRim = parseBoolean(park, 'sr', 'nr');

  return {
    dayCount,
    startDate,
    isSouthbound,
    isBrightAngel,
    parkAtSouthRim,
    maxDailyDistance,
    selectedTripId: id,
  };
};

export const buildQueryParams = ({
  dayCount,
  startDate,
  isSouthbound,
  isBrightAngel,
  parkAtSouthRim,
  maxDailyDistance,
  selectedTripId,
}) => {
  const payload = {};

  payload.id = selectedTripId || undefined;
  payload.start = serializeDate(startDate);
  payload.days = serializeNumber(dayCount);
  payload.distance = serializeNumber(maxDailyDistance);
  payload.direction = serializeBoolean(isSouthbound, 's', 'n');
  payload.route = serializeBoolean(isBrightAngel, 'bat', 'kt');
  payload.park = serializeBoolean(parkAtSouthRim, 'sr', 'nr');

  return queryString.stringify(payload);
};

export const filterSortTrips = (trips, filters) => {
  const {
    dayCount,
    isSouthbound,
    isBrightAngel,
    parkAtSouthRim,
    maxDailyDistance,
  } = filters;

  const filteredTrips = trips.filter(
    trip =>
      (!dayCount || dayCount === trip.trailItinerary.days.length) &&
      (!maxDailyDistance ||
        maxDailyDistance >= trip.trailItinerary.maxDailyDistance) &&
      (isSouthbound === null ||
        isSouthbound === trip.trailItinerary.isSouthbound) &&
      (isBrightAngel === null ||
        isBrightAngel === trip.trailItinerary.isBrightAngel) &&
      (parkAtSouthRim === null || parkAtSouthRim === trip.parkAtSouthRim),
  );

  return sortTrips(filteredTrips);
};

// TODO improve
export const findAlternativeTrips = (trips, idealTrip, options) => {
  const { isBrightAngel, isSouthbound } = idealTrip.trailItinerary;
  const dayCount = idealTrip.trailItinerary.days.length;

  const {
    allowReverseItinerary,
    allowFlexibleItinerary,
    enableFlexibleDayCounts,
    alternativeDayCounts,
  } = options;

  const dayCounts = [dayCount];
  if (enableFlexibleDayCounts) {
    dayCounts.push(...alternativeDayCounts);
  }

  const alternativeTrips = trips.filter(
    trip =>
      idealTrip !== trip &&
      dayCounts.includes(trip.trailItinerary.days.length) &&
      (allowReverseItinerary ||
        trip.trailItinerary.isSouthbound === isSouthbound) &&
      (allowFlexibleItinerary ||
        trip.trailItinerary.isBrightAngel === isBrightAngel) &&
      (allowFlexibleItinerary ||
        allowReverseItinerary ||
        trip.parkAtSouthRim === idealTrip.parkAtSouthRim),
  );

  return sortTrips(alternativeTrips);
};

const sortTrips = trips => trips.slice().sort((a, b) => b.score - a.score);

export const prioritizeTrips = (trips, filters) => {
  const filteredTrips = filterSortTrips(trips, filters);
  const bestTripScore = filteredTrips.length ? filteredTrips[0].score : 0;

  const bestTrips = [];
  const otherTrips = [];

  filteredTrips.forEach(trip => {
    if (trip.score >= bestTripScore) {
      bestTrips.push(trip);
    } else {
      otherTrips.push(trip);
    }
  });

  return [bestTrips, otherTrips];
};

export const isTripIdValid = tripId => TRIPS.some(trip => trip.id === tripId);

export const isStartDateValid = startDate => {
  return !!startDate && !!findApplicationPeriodForStartDate(startDate);
};
