import moment from 'moment';

import STOPS from 'data/stops';

// Based on the 2020 schedule:
// https://www.trans-canyonshuttle.com/rim-to-rim-shuttle-schedules
// TODO: use mountain timezone
const getNorthboundSchedule = year => {
  return [
    {
      from: moment.utc([year, 4, 15]),
      to: moment.utc([year, 10, 15]),
      departure: moment.duration('08:00'),
      arrival: moment.duration('12:30'),
    },
    {
      from: moment.utc([year, 4, 15]),
      to: moment.utc([year, 9, 15]),
      departure: moment.duration('13:30'),
      arrival: moment.duration('18:00'),
    },
  ];
};

const getSouthboundSchedule = year => {
  return [
    {
      from: moment.utc([year, 4, 15]),
      to: moment.utc([year, 9, 16]),
      departure: moment.duration('07:00'),
      arrival: moment.duration('11:30'),
    },
    {
      from: moment.utc([year, 4, 15]),
      to: moment.utc([year, 10, 15]),
      departure: moment.duration('14:00'),
      arrival: moment.duration('18:30'),
    },
  ];
};

export const getRimToRimShuttleTimes = (stop, date) => {
  const year = date.year();
  let schedule = [];
  if (stop === STOPS.NKP) {
    schedule = getSouthboundSchedule(year);
  } else if (stop === STOPS.BAL) {
    schedule = getNorthboundSchedule(year);
  }
  return schedule.map(ride => [
    moment.utc(ride.departure.as('milliseconds')),
    moment.utc(ride.arrival.as('milliseconds')),
  ]);
};
