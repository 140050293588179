const eventTypes = {
  application: {
    start: 'application.start',
  },
  index: {
    startDate: {
      update: 'index.startDate.update',
      pick: 'index.startDate.pick',
    },
  },
  trips: {
    filters: {
      update: 'trips.filters.update',
      clear: 'trips.filters.clear',
    },
    selectedTrip: {
      update: 'trips.selectedTrip.update',
      pick: 'trips.selectedTrip.pick',
    },
  },
  trip: {
    permit: {
      download: 'trip.permit.download',
      apply: 'trip.permit.apply',
    },
  },
  checkout: {
    navigation: {
      backTo: 'checkout.navigation.backTo',
    },
    leader: {
      submit: 'checkout.leader.submit',
    },
    group: {
      submit: 'checkout.group.submit',
    },
    alternatives: {
      submit: 'checkout.alternatives.submit',
    },
    payment: {
      success: 'checkout.payment.success',
      error: 'checkout.payment.error',
    },
    submit: {
      success: 'checkout.submit.success',
      error: 'checkout.submit.error',
    },
  },
};

export default eventTypes;
