import React from 'react';

import Filter from './Filter';
import { Item } from './Menu';

import './DayCountFilter.scss';

const formatDayCount = dayCount =>
  dayCount === 1 ? '1 day' : `${dayCount} days`;

const MaxDailyTotalAscentFilter = ({ dayCount, onChange }) => {
  const triggerLabel = dayCount ? formatDayCount(dayCount) : 'Days';

  return (
    <Filter triggerLabel={triggerLabel} isActive={dayCount !== null}>
      <Item
        label={formatDayCount(2)}
        isSelected={2 === dayCount}
        key={2}
        onClick={() => onChange(2)}
      >
        <div className="Trips/DayCountFilter__item-description">
          Longest day: 14&ndash;19 miles
        </div>
      </Item>
      <Item
        label={formatDayCount(3)}
        isSelected={3 === dayCount}
        key={3}
        onClick={() => onChange(3)}
      >
        <div className="Trips/DayCountFilter__item-description">
          Longest day: 7&ndash;14 miles
        </div>
      </Item>
      <Item
        label={formatDayCount(4)}
        isSelected={4 === dayCount}
        key={4}
        onClick={() => onChange(4)}
      >
        <div className="Trips/DayCountFilter__item-description">
          Longest day: 7 miles
        </div>
      </Item>
      <Item
        label="No Preference"
        isSelected={dayCount === null}
        onClick={() => onChange(null)}
      />
    </Filter>
  );
};

export default MaxDailyTotalAscentFilter;
