import React from 'react';

import { Tall } from 'components/Layout';
import Chart from 'components/Chart';
import Tooltip from 'components/Tooltip';
import Attribution from 'components/StaticMap/Attribution';
import TRIPS from 'data/trips';
import STOPS from 'data/stops';
import overview from 'assets/overview.png';
import {
  NPS_APPLICATION_PRICE,
  NPS_PER_PERSON_PER_NIGHT_PRICE,
  RIM_TO_RIM_SHUTTLE_PER_PERS_PRICE,
  getAveragePerPersonTotalPrice,
  formatPrice,
} from 'utils/pricing';

import './Overview.scss';

const trip = TRIPS.find(
  trip =>
    trip.days.length === 4 &&
    trip.trailItinerary.start === STOPS.NKT &&
    trip.trailItinerary.end === STOPS.BAT &&
    trip.entryItinerary.start === STOPS.LD,
);

const Overview = () => (
  <section className="Index/Overview">
    <div className="Index/Overview__column">
      <h2 className="Index/Overview__title">The Trail</h2>
      <Tall size={40} />
      <p className="Index/Overview__text">
        While there are many ways to hike Rim to Rim, we recommend one-way
        trips. Daily shuttles allows you to travel to and from the trailheads.
      </p>
      <Tall size={40} />
      <table className="Index/Overview__table">
        <tbody>
          <tr>
            <td>Distance</td>
            <td>21&ndash;24 miles</td>
          </tr>
          <tr>
            <td>Elevation gain</td>
            <td>4,300&ndash;5,400 ft</td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>2&ndash;4 days</td>
          </tr>
          <tr>
            <td>Average price</td>
            <td>
              <Tooltip
                content={
                  <>
                    Rim to Rim shuttle:{' '}
                    {formatPrice(RIM_TO_RIM_SHUTTLE_PER_PERS_PRICE)}/pers.
                    <br />
                    Campground: {formatPrice(NPS_PER_PERSON_PER_NIGHT_PRICE)}
                    /pers./night
                    <br />
                    Permit application: {formatPrice(NPS_APPLICATION_PRICE)}
                  </>
                }
              >
                {formatPrice(getAveragePerPersonTotalPrice())}/pers.
              </Tooltip>
            </td>
          </tr>
        </tbody>
      </table>
      <Tall size={80} />
      <Chart trip={trip} height={180} width={300} backgroundColor="#f9f4ec" />
    </div>
    <img
      src={overview}
      className="Index/Overview__image"
      alt="Rim to Rim trail overview"
    />
    <div className="Index/Overview__map-attribution">
      <Attribution />
    </div>
  </section>
);

export default Overview;
