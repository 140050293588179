import React from 'react';

import { ReactComponent as MapboxLogo } from 'assets/mapbox-logo.svg';

import './Attribution.scss';

const Attribution = () => (
  <div className="StaticMap/Attribution">
    <MapboxLogo className="StaticMap/Attribution__logo" />
    <a
      className="StaticMap/Attribution__link"
      href="https://www.mapbox.com/about/maps/"
      target="_blank"
      rel="noopener noreferrer"
    >
      © Mapbox
    </a>
    <a
      className="StaticMap/Attribution__link"
      href="http://www.openstreetmap.org/copyright"
      target="_blank"
      rel="noopener noreferrer"
    >
      © OpenStreetMap
    </a>
  </div>
);

export default Attribution;
