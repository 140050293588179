export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_DEPLOY_CONTEXT === 'production';

export const IS_LOCAL_DEVELOPMENT =
  process.env.NODE_ENV === 'development' &&
  !process.env.REACT_APP_DEPLOY_CONTEXT;

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoiam9yZGFudmluY2VudCIsImEiOiJjazN4ZnNzaWIwNHQxM2Zub211dnlxcXJmIn0.1Up4udxbdJyj8n3orz177w';
export const MAPBOX_STYLE = IS_PRODUCTION
  ? 'jordanvincent/ckb8l1bmk04oa1ipcwbjqknxv'
  : 'jordanvincent/ckb8l1bmk04oa1ipcwbjqknxv/draft';

export const AMPLITUDE_API_KEY = '9ec87ec67d9405bbfd3d4df710cbc090';

// WARNING: these ids must match those in index.html
export const GOOGLE_ANALYTICS_ID = 'UA-179175753-1';
export const GOOGLE_ADS_ID = 'AW-550888854';
export const GOOGLE_ADS_CONVERSION_ID = 'AW-550888854/0uSYCMWTk-IBEJbL14YC';
