import React from 'react';

import { lngToX, latToY } from './utils';

import './ShuttleSegment.scss';

const labelHeight = 28;
const labelWidth = 110;

const ShuttleSegment = ({ start, end, isPaid }) => {
  const startPoint = [lngToX(start.longitude), latToY(start.latitude)];
  const endPoint = [lngToX(end.longitude), latToY(end.latitude)];

  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const distance = Math.sqrt(dx * dx + dy * dy);
  const altitude = distance / 3;
  const unitVector = [dx / distance, dy / distance];
  let normal = [altitude * unitVector[1], -altitude * unitVector[0]];

  // Change direction of normal
  if ((isPaid && normal[0] > 0) || (!isPaid && normal[1] < 0)) {
    normal = [-normal[0], -normal[1]];
  }

  const centerPoint = [
    (startPoint[0] + endPoint[0]) / 2,
    (startPoint[1] + endPoint[1]) / 2,
  ];
  let summitPoint = [centerPoint[0] + normal[0], centerPoint[1] + normal[1]];

  const offset = 20; // Offset from point in px
  const f = (2 / distance) * offset;
  const offsetNormal = [normal[0] * f, normal[1] * f];

  const offsetStartPoint = [
    startPoint[0] + unitVector[0] * offset + offsetNormal[0],
    startPoint[1] + unitVector[1] * offset + offsetNormal[1],
  ];

  const offsetEndPoint = [
    endPoint[0] - unitVector[0] * offset + offsetNormal[0],
    endPoint[1] - unitVector[1] * offset + offsetNormal[1],
  ];

  const d = `M ${offsetStartPoint[0]} ${offsetStartPoint[1]} Q ${summitPoint[0]} ${summitPoint[1]} ${offsetEndPoint[0]} ${offsetEndPoint[1]}`;

  const labelNudge = isPaid ? [120, 0] : [0, 0];
  const labelText = isPaid ? 'Paid Shuttle' : 'Free Shuttles';

  return (
    <g className="StaticMap/ShuttleSegment">
      <path
        className="StaticMap/ShuttleSegment__curve"
        d={d}
        markerEnd="url(#arrowMarker)"
      />
      <g
        transform={`translate(${summitPoint[0] -
          labelWidth / 2 +
          labelNudge[0]} ${summitPoint[1] - labelHeight / 2 + labelNudge[1]})`}
      >
        <rect
          className="StaticMap/ShuttleSegment__label-back"
          width={labelWidth}
          height={labelHeight}
          rx={labelHeight / 2}
        />
        <text
          className="StaticMap/ShuttleSegment__label"
          x={labelWidth / 2}
          y={labelHeight / 2 + 1}
        >
          {labelText}
        </text>
      </g>
    </g>
  );
};

export default ShuttleSegment;
