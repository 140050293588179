import React from 'react';

import picture from 'assets/picture.jpg';
import { Tall } from 'components/Layout';
import Footer from 'components/Footer';

import StartDate from './StartDate';
import Overview from './Overview';

import './index.scss';

const Index = () => {
  return (
    <div className="Index">
      <Tall size={100} />
      <header className="Index__header">
        <img src={picture} className="Index__picture" alt="North Rim trail" />

        <div className="Index__heading">
          <h1 className="Index__title">
            <div>Backpack</div>
            <div>Rim to Rim Trail</div>
            <div>Grand Canyon National Park</div>
          </h1>
          <Tall size={60} />
          <p>
            You’re just <u>10 minutes</u> away from planning one of the best
            backpacking trips in the world!
          </p>
          <Tall size={60} />
          <div>We help you find:</div>
          <Tall size={20} />
          <ul>
            <li>
              <span className="Index__value">The right itinerary</span>
            </li>
            <li>
              <span className="Index__value">The best time to hike</span>
            </li>
          </ul>
        </div>
      </header>
      <Tall size={200} />
      <Overview />
      <Tall size={160} />
      <StartDate />
      <Tall size={200} />
      <div className="Index__footer">
        <Footer />
      </div>
    </div>
  );
};

export default Index;
