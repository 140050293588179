import React from 'react';

import { ReactComponent as ArrowIcon } from 'assets/direction-arrow.svg';

import { lngToX, latToY } from './utils';

import './Arrow.scss';

const Arrow = ({ isSouthbound }) => {
  const x = lngToX(-112.05659866333006);
  const y = latToY(36.1464695411456);
  const angle = isSouthbound ? 125 : -55;

  return (
    <g
      className="StaticMap/Arrow"
      transform={`translate(${x} ${y}) rotate(${angle} 0 16)`}
    >
      <ArrowIcon />
    </g>
  );
};

export default Arrow;
