import React from 'react';

import { lngToX, latToY } from './utils';

import { ReactComponent as CampgroundIcon } from 'assets/campground.svg';
import { ReactComponent as HotelIcon } from 'assets/hotel.svg';
import { ReactComponent as ParkingIcon } from 'assets/parking.svg';

import './Stop.scss';

const borderWidth = 3;
const radius = 10;
const size = 19;
const outerRadius = radius + borderWidth;
const outerSize = size + borderWidth * 2;

const Stop = ({ stop }) => {
  let content;
  if (stop.isCampground) {
    content = (
      <>
        <circle className="StaticMap/Stop__back" r={outerRadius} />
        <circle className="StaticMap/Stop__front" r={radius} />
        <CampgroundIcon x={-8} y={-9} />
      </>
    );
  } else if (stop.isHotel) {
    content = (
      <>
        <circle className="StaticMap/Stop__back" r={outerRadius} />
        <circle className="StaticMap/Stop__front" r={radius} />
        <HotelIcon x={-8} y={-9} />
      </>
    );
  } else if (stop.isParking) {
    content = (
      <g transform={`translate(${-size / 2} ${-size / 2})`}>
        <rect
          className="StaticMap/Stop__back"
          width={outerSize}
          height={outerSize}
          rx={5}
          x={-borderWidth}
          y={-borderWidth}
        />
        <rect
          className="StaticMap/Stop__front"
          width={size}
          height={size}
          rx={2}
          x={0}
          y={0}
        />
        <ParkingIcon x={1} y={1.5} />
      </g>
    );
  }

  return (
    <g
      className="StaticMap/Stop"
      transform={`translate(${lngToX(stop.longitude)} ${latToY(
        stop.latitude,
      )})`}
    >
      {content}
      {/*
        Hard-coding values here because displaying absolutely positioned
        foreignObject in Safari is buggy.
        Setting a non-zero height fixes a bug in Firefox.
      */}
      <foreignObject
        x="24"
        y="-16"
        width="120"
        height="1"
        className="StaticMap/Stop__label"
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          className="StaticMap/Stop__label-inner"
        >
          {stop.name}
        </div>
      </foreignObject>
    </g>
  );
};

export default Stop;
