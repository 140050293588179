import React from 'react';

import Filter from './Filter';
import { Item } from './Menu';

import './ParkingFilter.scss';

const ParkingFilter = ({ parkAtSouthRim, onChange }) => {
  let triggerLabel = 'Parking';
  if (parkAtSouthRim === true) {
    triggerLabel = 'Park at South Rim';
  } else if (parkAtSouthRim === false) {
    triggerLabel = 'Park at North Rim';
  }

  return (
    <Filter triggerLabel={triggerLabel} isActive={parkAtSouthRim !== null}>
      <Item
        label="Park at South Rim"
        isSelected={parkAtSouthRim === true}
        onClick={() => onChange(true)}
      >
        <div className="Trips/ParkingFilter__item-description">
          <span className="Trips/ParkingFilter__item-pro">
            Right by the Backcountry Information Center.
          </span>
        </div>
        <div className="Trips/ParkingFilter__estimates">
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Las Vegas</div>
            <div className="Trips/ParkingFilter__duration">4h15</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Page</div>
            <div className="Trips/ParkingFilter__duration">2h30</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">North Rim</div>
            <div className="Trips/ParkingFilter__duration">4h15</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Flagstaff</div>
            <div className="Trips/ParkingFilter__duration">1h30</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Phoenix</div>
            <div className="Trips/ParkingFilter__duration">3h30</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Williams</div>
            <div className="Trips/ParkingFilter__duration">1h00</div>
          </div>
        </div>
      </Item>
      <Item
        label="Park at North Rim"
        isSelected={parkAtSouthRim === false}
        onClick={() => onChange(false)}
      >
        <div className="Trips/ParkingFilter__item-description">
          <span className="Trips/ParkingFilter__item-con">
            Further from most cities.
          </span>
        </div>
        <div className="Trips/ParkingFilter__estimates">
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Las Vegas</div>
            <div className="Trips/ParkingFilter__duration">4h30</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Page</div>
            <div className="Trips/ParkingFilter__duration">2h30</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">South Rim</div>
            <div className="Trips/ParkingFilter__duration">4h15</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Flagstaff</div>
            <div className="Trips/ParkingFilter__duration">3h45</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Phoenix</div>
            <div className="Trips/ParkingFilter__duration">5h45</div>
          </div>
          <div className="Trips/ParkingFilter__estimate">
            <div className="Trips/ParkingFilter__destination">Williams</div>
            <div className="Trips/ParkingFilter__duration">4h15</div>
          </div>
        </div>
      </Item>
      <Item
        label="No Preference"
        isSelected={parkAtSouthRim === null}
        onClick={() => onChange(null)}
      />
    </Filter>
  );
};

export default ParkingFilter;
