const STOP_LIST = [
  {
    code: 'BAT',
    name: 'Bright Angel Trailhead',
    abbreviatedName: 'Bright Angel',
    latitude: 36.05779023145284,
    longitude: -112.14318037033081,
    altitude: 6840,
    isTrailhead: true,
    isSouthRim: true,
  },
  {
    code: 'IG',
    name: 'Indian Garden Campground',
    abbreviatedName: 'Indian Garden',
    latitude: 36.07707761013974,
    longitude: -112.12905049324036,
    altitude: 3800,
    isAccommodation: true,
    isCampground: true,
  },
  {
    code: 'SKT',
    name: 'South Kaibab Trailhead',
    abbreviatedName: 'South Kaibab',
    latitude: 36.052859223143315,
    longitude: -112.08382308483124,
    altitude: 7200,
    isTrailhead: true,
    isSouthRim: true,
  },
  {
    code: 'BAC',
    name: 'Bright Angel Campground',
    abbreviatedName: 'Bright Angel',
    latitude: 36.10122352594637,
    longitude: -112.09584474563599,
    altitude: 2500,
    isAccommodation: true,
    isCampground: true,
  },
  {
    code: 'PR',
    name: 'Phantom Ranch',
    abbreviatedName: 'Phantom Ranch',
    latitude: 36.10604748491313,
    longitude: -112.09465384483337,
    altitude: 2500,
    isAccommodation: true,
    isHotel: true,
  },
  {
    code: 'CW',
    name: 'Cottonwood Campground',
    abbreviatedName: 'Cottonwood',
    latitude: 36.1704122451274,
    longitude: -112.04048395156859,
    altitude: 4040,
    isAccommodation: true,
    isCampground: true,
  },
  {
    code: 'NKT',
    name: 'North Kaibab Trailhead',
    abbreviatedName: 'North Kaibab',
    latitude: 36.21695137677603,
    longitude: -112.05671668052673,
    altitude: 8240,
    isTrailhead: true,
  },
  {
    code: 'NKP',
    name: 'North Kaibab Trailhead Parking',
    abbreviatedName: 'North Kaibab Park.',
    latitude: 36.2174793830529,
    longitude: -112.05606758594513,
    altitude: 8240,
    isParking: true,
  },
  {
    code: 'LD',
    name: 'South Rim Parking Lot D',
    abbreviatedName: 'South Rim Park.',
    latitude: 36.053470736996935,
    longitude: -112.14313745498657,
    altitude: 6840,
    isParking: true,
    isSouthRim: true,
  },
  {
    code: 'BIC',
    name: 'Backcountry Information Center',
    abbreviatedName: 'Backcountry Information Center',
    latitude: 36.052434196531514,
    longitude: -112.14364171028137,
    altitude: 6840,
    isSouthRim: true,
  },
  {
    code: 'BAL',
    name: 'Bright Angel Lodge',
    abbreviatedName: 'Bright Angel Lodge',
    latitude: 36.056974923341606,
    longitude: -112.1409273147583,
    altitude: 6840,
    isSouthRim: true,
  },
  {
    code: 'GCVC',
    name: 'Grand Canyon Visitor Center',
    abbreviatedName: 'Visitor Center',
    latitude: 36.05821956583956,
    longitude: -112.10840821266173,
    altitude: 7100,
    isSouthRim: true,
  },
];

export const PARKINGS = STOP_LIST.filter(stop => stop.isParking);
export const TRAILHEADS = STOP_LIST.filter(stop => stop.isTrailhead);

// Index on stop code
export default STOP_LIST.reduce((stops, stop) => {
  stops[stop.code] = stop;
  return stops;
}, {});
