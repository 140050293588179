import React from 'react';
import numeral from 'numeral';

import SEGMENT_TYPES from 'data/segment-types';
import { getRimToRimShuttleTimes } from 'data/shuttle';
import Tooltip from 'components/Tooltip';

import Item from './Item';
import Group from './Group';

const formatDuration = duration => {
  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 60);
  const parts = [];

  if (hours) {
    parts.push(`${hours} h`);
  }
  if (minutes) {
    parts.push(`${minutes} min`);
  }
  return parts.join(' ');
};

const formatElevation = elevation =>
  `${numeral(Math.round(elevation / 100) * 100).format('0,0')} ft`;

const getSegmentItem = (segment, date) => {
  let instruction = '';
  let description;

  if (segment.type === SEGMENT_TYPES.HIKING) {
    instruction = 'Hike';
    description = `${numeral(segment.distance).format()} miles`;
    if (segment.totalAscent) {
      // TODO: color ascent in red
      description += ` ${formatElevation(segment.totalAscent)} ascent`;
    }
    if (segment.totalDescent) {
      description += ` ${formatElevation(segment.totalDescent)} descent`;
    }
  } else if (segment.type === SEGMENT_TYPES.WALKING) {
    instruction = 'Walk';
    description = formatDuration(segment.duration);
  } else if (segment.type === SEGMENT_TYPES.SHUTTLE) {
    if (segment.isPaid) {
      instruction = `Take the Rim to Rim shuttle`;
      const times = getRimToRimShuttleTimes(segment.start, date);
      description = times.map(([departure, arrival], index) => {
        // TODO: remove AM/PM if both times are in the morning/evening
        const formattedTime = `${departure.format('LT')}–${arrival.format(
          'LT',
        )}`;
        const tooltipContent = (
          <div>
            Leaves {segment.start.name} at {departure.format('LT')}
            <br />
            Arrives at {segment.end.name} at {arrival.format('LT')}
          </div>
        );

        return (
          <React.Fragment key={formattedTime}>
            <Tooltip content={tooltipContent}>{formattedTime}</Tooltip>
            {index < times.length - 1 && <span>&emsp;</span>}
          </React.Fragment>
        );
      });
    } else {
      const duration = formatDuration(segment.duration);
      instruction = `Take the free shuttle`;
      description = (
        <>
          {duration}&emsp;
          <Tooltip content="Between sunrise and sunset">Every 15 min</Tooltip>
        </>
      );
    }
  }

  return {
    name: `${instruction} to ${segment.end.name}`,
    type: segment.type,
    description,
    items: [],
  };
};

const getParkingItem = trip => {
  return {
    name: `Park at ${trip.days[0].segments[0].start.name}`,
    type: null,
    items: [],
  };
};

const Detailed = ({ trip, startDate }) => {
  return trip.days.map((day, index) => {
    const date = startDate.clone().add(index, 'day');
    const formattedDate = date.format('ddd, MMMM D YYYY');

    return (
      <Group title={formattedDate} key={formattedDate}>
        {index === 0 && <Item item={getParkingItem(trip)} />}
        {day.segments.map(segment => {
          const item = getSegmentItem(segment, date);
          return <Item item={item} key={item.name} />;
        })}
      </Group>
    );
  });
};

export default Detailed;
