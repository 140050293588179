import React from 'react';
import momentPropTypes from 'react-moment-proptypes';

import './Weekday.scss';

const Weekday = ({ weekday }) => {
  return <div className="Calendar/Weekday">{weekday.format('dd')}</div>;
};

Weekday.propTypes = {
  weekday: momentPropTypes.momentObj.isRequired,
};

export default Weekday;
