import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import Timeline from 'components/Timeline';

import Chart from 'components/Chart';
import { Tall } from 'components/Layout';

import './index.scss';

const getIsAtBound = (parentRef, childRef) => {
  const y = parentRef.current.scrollTop;
  const parentH = parentRef.current.offsetHeight;
  const childH = childRef.current.offsetHeight;
  return [y <= 0, parentH + y >= childH];
};

const TripCard = ({ trip, startDate, isHighlighted, stretch, onPick }) => {
  const parentRef = useRef();
  const childRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAtBound, setIsAtBound] = useState([false, false]);
  const { days, routeName, directionName } = trip;
  const dayCount = days.length;
  const endDate = startDate.clone().add(dayCount - 1, 'day');

  // TODO replace with CSS scroll shadows
  const handleScroll = () => {
    setIsAtBound(getIsAtBound(parentRef, childRef));
  };

  // Resets scroll when trip changes or is expanded
  useEffect(() => {
    parentRef.current.scrollTo(0, 0);
    setIsAtBound(getIsAtBound(parentRef, childRef));
  }, [trip, isExpanded]);

  // Reduce when trip changes
  useEffect(() => {
    setIsExpanded(false);
  }, [trip]);

  return (
    <div
      className={classnames('TripCard', {
        'TripCard--highlighted': isHighlighted,
        'TripCard--stretch': stretch,
      })}
    >
      <div className="TripCard__header">
        <div className="TripCard__title">
          {directionName} {routeName} in&nbsp;{dayCount}&nbsp;
          {dayCount === 1 ? 'day' : 'days'}
        </div>
        {onPick && (
          <button
            type="button"
            className="TripCard__pick-button"
            onClick={onPick}
          >
            Pick
          </button>
        )}
      </div>

      <div
        className={classnames('TripCard__timeline-wrapper', {
          'TripCard__timeline-wrapper--at-top': isAtBound[0],
          'TripCard__timeline-wrapper--at-bottom': isAtBound[1],
        })}
        onScroll={handleScroll}
      >
        <div
          className="TripCard__timeline"
          onScroll={handleScroll}
          ref={parentRef}
        >
          <div className="TripCard__timeline-inner" ref={childRef}>
            {!isExpanded && (
              <>
                <div className="TripCard__date-range">
                  {startDate.format('ddd, MMM D')} &rarr;{' '}
                  {endDate.format('ddd, MMM D YYYY')}
                </div>
                <Tall size={32} />
                <Chart trip={trip} />
                <Tall size={32} />
              </>
            )}
            <Timeline
              trip={trip}
              startDate={startDate}
              isExpanded={isExpanded}
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        className="TripCard__expand-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'Hide details' : 'Show details'}
      </button>
    </div>
  );
};

export default TripCard;
