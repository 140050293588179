import React from 'react';
import classnames from 'classnames';

import './Stop.scss';

const Stop = ({ stop, x, yScale, textAlign = 'center' }) => {
  const y = yScale(stop.altitude);

  return (
    <g transform={`translate(${x} ${y})`}>
      {stop.isCampground ? (
        <>
          <circle r={6} className="Chart/Stop__backdrop" />
          <circle r={6} className="Chart/Stop__inner" />
          <polygon
            transform="translate(-3 -3.5)"
            points="0 6 3 0 6 6 4 6 3 4 2 6"
            className="Chart/Stop__icon"
          />
        </>
      ) : (
        <>
          <rect
            x={-6}
            y={-6}
            width={12}
            height={12}
            rx={2}
            className="Chart/Stop__backdrop"
          />
          <rect
            x={-6}
            y={-6}
            width={12}
            height={12}
            rx={2}
            className="Chart/Stop__inner"
          />
          <path
            className="Chart/Stop__icon"
            transform="translate(-3.5 -2)"
            d="M3,0 L3,1 L2,1 L2,4 L1,4 L1,1 L0,1 L0,0 L3,0 Z M5,0 L5,1.5 L6,1.5 L6,0 L7,0 L7,4 L6,4 L6,2.5 L5,2.5 L5,4 L4,4 L4,0 L5,0 Z"
          ></path>
        </>
      )}

      {/* Setting a non-zero width/height fixes a bug in Firefox */}
      <foreignObject
        x="0"
        y="0"
        width="1"
        height="1"
        className="Chart/Stop__label"
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          className={classnames(
            'Chart/Stop__label-inner',
            `Chart/Stop__label-inner--${textAlign}`,
          )}
        >
          {stop.abbreviatedName}
        </div>
      </foreignObject>
    </g>
  );
};

export default Stop;
