import { estimateDuration } from 'utils';
import { sumBy } from 'utils/array';

import STOPS from './stops';
import SEGMENT_TYPE from './segment-types';

const NORTHBOUND_FOOT_SEGMENTS = [
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'A1',
    start: STOPS.BAT,
    end: STOPS.IG,
    totalAscent: 0,
    totalDescent: 3040,
    distance: 4.5,
  },
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'A2',
    start: STOPS.IG,
    end: STOPS.BAC,
    totalAscent: 0,
    totalDescent: 1300,
    distance: 5,
  },
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'K1',
    start: STOPS.SKT,
    end: STOPS.BAC,
    totalAscent: 0,
    totalDescent: 4700,
    distance: 7,
  },
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'K2',
    start: STOPS.BAC,
    end: STOPS.PR,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.3,
  },
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'K3',
    start: STOPS.PR,
    end: STOPS.CW,
    totalAscent: 1540,
    totalDescent: 0,
    distance: 6.9,
  },
  {
    type: SEGMENT_TYPE.HIKING,
    code: 'K4',
    start: STOPS.CW,
    end: STOPS.NKT,
    totalAscent: 4200,
    totalDescent: 0,
    distance: 6.5,
  },
  {
    type: SEGMENT_TYPE.WALKING,
    code: 'W1',
    start: STOPS.BIC,
    end: STOPS.LD,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.08,
  },
  {
    type: SEGMENT_TYPE.WALKING,
    code: 'W2',
    start: STOPS.LD,
    end: STOPS.BAL,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.5,
  },
  {
    type: SEGMENT_TYPE.WALKING,
    code: 'W3',
    start: STOPS.BAL,
    end: STOPS.BAT,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.2,
  },
  {
    type: SEGMENT_TYPE.WALKING,
    code: 'W4',
    start: STOPS.LD,
    end: STOPS.BAT,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.4,
  },
  {
    type: SEGMENT_TYPE.WALKING,
    code: 'W5',
    start: STOPS.NKT,
    end: STOPS.NKP,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0.08,
  },
];

const SHUTTLE_SEGMENTS = [
  {
    type: SEGMENT_TYPE.SHUTTLE,
    isPaid: true,
    code: 'S1N',
    start: STOPS.BAL,
    end: STOPS.NKP,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 4.5,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    isPaid: true,
    code: 'S1S',
    start: STOPS.NKP,
    end: STOPS.BAL,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 4.5,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S2N',
    start: STOPS.SKT,
    end: STOPS.GCVC,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 14 / 60,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S2S',
    start: STOPS.GCVC,
    end: STOPS.SKT,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 8 / 60,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S3N',
    start: STOPS.BAL,
    end: STOPS.GCVC,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 28 / 60,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S3S',
    start: STOPS.GCVC,
    end: STOPS.BAL,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 11 / 60,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S4N',
    start: STOPS.BIC,
    end: STOPS.GCVC,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 17 / 60,
  },
  {
    type: SEGMENT_TYPE.SHUTTLE,
    code: 'S4S',
    start: STOPS.GCVC,
    end: STOPS.BIC,
    totalAscent: 0,
    totalDescent: 0,
    distance: 0,
    duration: 18 / 60,
  },
];

const generateFootSegments = () => {
  return NORTHBOUND_FOOT_SEGMENTS.reduce((collector, northBoundSegment) => {
    const {
      code,
      start,
      end,
      totalAscent,
      totalDescent,
      distance,
    } = northBoundSegment;

    collector[`${code}N`] = {
      ...northBoundSegment,
      code: `${code}N`,
      duration: estimateDuration(distance, totalAscent - totalDescent),
    };

    collector[`${code}S`] = {
      ...northBoundSegment,
      code: `${code}S`,
      start: end,
      end: start,
      totalAscent: totalDescent,
      totalDescent: totalAscent,
      duration: estimateDuration(distance, totalDescent - totalAscent),
    };

    return collector;
  }, {});
};

const generateShuttleSegments = () => {
  return SHUTTLE_SEGMENTS.reduce((collector, segment) => {
    collector[segment.code] = segment;
    return collector;
  }, {});
};

export const generateCombinedSegment = segments => {
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];
  return {
    type: firstSegment.type,
    code: segments.map(segment => segment.code).join('|'),
    start: firstSegment.start,
    end: lastSegment.end,
    totalAscent: sumBy(segments, 'totalAscent'),
    totalDescent: sumBy(segments, 'totalDescent'),
    distance: sumBy(segments, 'distance'),
    duration: sumBy(segments, 'duration'),
    subSegments: segments.slice(),
  };
};

const segments = {
  ...generateFootSegments(),
  ...generateShuttleSegments(),
};

export default segments;
