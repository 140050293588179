import React from 'react';

import StaticMap from 'components/StaticMap';
import Trip from 'components/StaticMap/Trip';
import Attribution from 'components/StaticMap/Attribution';
import TripCard from 'components/TripCard';

import './Summary.scss';

const Summary = ({ trip, startDate }) => {
  return (
    <div className="Trip/Summary">
      <div className="Trip/Summary__left-column">
        <h2 className="Trip/Summary__title">Details</h2>
        <div className="Trip/Summary__card-wrapper">
          <TripCard trip={trip} startDate={startDate} />
        </div>
      </div>
      <div className="Trip/Summary__right-column">
        <StaticMap noAttribution>
          <Trip trip={trip} />
        </StaticMap>
      </div>
      <div className="Trip/Summary__map-attribution">
        <Attribution />
      </div>
    </div>
  );
};

export default Summary;
