import React from 'react';

import SEGMENT_TYPE from 'data/segment-types';
import { generateCombinedSegment } from 'data/segments';

import HikingSegment from './HikingSegment';
import Stop from './Stop';

import './index.scss';

const PIXELS_PER_HOUR = 10;
const MIN_ELEVATION = 2500 - 500;
const MAX_ELEVATION = 8240;
const GUTTER_WIDTH = 2;

const PADDING = {
  top: 40,
  right: 0,
  bottom: 52,
  left: 0,
};

const getDisplaySegments = trip => {
  const { days } = trip;
  let allSegments = days.map(({ segments }) => segments).flat();
  return allSegments
    .filter(({ type }) => type === SEGMENT_TYPE.HIKING)
    .map(({ subSegments }) => generateCombinedSegment(subSegments));
};

// TODO derive width from container width
const Chart = ({
  trip,
  width = 296,
  height = 150,
  backgroundColor = 'white',
}) => {
  const innerHeight = height - PADDING.top - PADDING.bottom;
  const innerWidth = width - PADDING.left - PADDING.right;

  const displayedSegments = getDisplaySegments(trip);

  const yScale = elevation => {
    const maxDelta = MAX_ELEVATION - MIN_ELEVATION;
    const delta = elevation - MIN_ELEVATION;
    return Math.round((1 - delta / maxDelta) * innerHeight);
  };

  const totalHikingDistance = displayedSegments.reduce((total, segment) => {
    if (segment.type === SEGMENT_TYPE.HIKING) {
      total += segment.distance;
    }
    return total;
  }, 0);

  const pixelsPerMile =
    (innerWidth - GUTTER_WIDTH * displayedSegments.length) /
    totalHikingDistance;

  let offsetX = GUTTER_WIDTH / 2;

  return (
    <svg
      className="Chart"
      viewBox={`0 0 ${width} ${height}`}
      height={height}
      width={width}
      style={{ '--background-color': backgroundColor }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`translate(${PADDING.left} ${PADDING.top})`}>
        {displayedSegments.map((segment, index) => {
          let content = [];
          const segmentWidth =
            segment.type === SEGMENT_TYPE.HIKING
              ? segment.distance * pixelsPerMile
              : segment.duration * PIXELS_PER_HOUR;

          content.push(
            <HikingSegment
              segment={segment}
              height={innerHeight}
              yScale={yScale}
              pixelsPerMile={pixelsPerMile}
              key="segment"
            />,
            <Stop
              x={-GUTTER_WIDTH / 2}
              yScale={yScale}
              stop={segment.start}
              textAlign={index === 0 ? 'left' : 'center'}
              key="start"
            />,
          );

          // Last stop
          if (index >= displayedSegments.length - 1) {
            content.push(
              <Stop
                x={segmentWidth + GUTTER_WIDTH / 2}
                yScale={yScale}
                stop={segment.end}
                textAlign="right"
                key="end"
              />,
            );
          }

          const result = (
            <g transform={`translate(${offsetX} ${0})`} key={segment.code}>
              {content}
            </g>
          );

          offsetX = offsetX + segmentWidth + GUTTER_WIDTH;

          return result;
        })}
        <line
          x1={0}
          y1={innerHeight + 25}
          x2={innerWidth}
          y2={innerHeight + 25}
          stroke="rgba(0,0,0,0.1)"
        />
      </g>
    </svg>
  );
};

export default Chart;
